import React, { useEffect, useState } from 'react';
import { Canvas, useThree } from '@react-three/fiber';
import { OrbitControls, PerspectiveCamera, useGLTF } from '@react-three/drei';
import * as THREE from 'three';
import { useColorContext } from '../ColorContext';
import { Container, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

// Styled container using MUI
const StyledContainer = styled(Container)(({ theme }) => ({
  height: '88vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

const SceneContent = () => {

  
  const { camera } = useThree();
  const {
    selectedArchIndex,
    selectedColorGetIndex,
    selectedbackcolor,
    selectedHandleIndex,
    selectedGrillIndex,
    selectedbackfront,
    typeOfDoor,
    sideLightGrill,
  } = useColorContext();




  const modelPath = typeOfDoor === 'DoubleDoor'
    ? '../assets/with-sidelight-and-transom-r10.gltf'
    : '../assets/operable-glass-r11.gltf';

  const { scene } = useGLTF(modelPath); // Use useGLTF directly
  





  // resize object

  const [cameraSettings, setCameraSettings] = useState({
    fov: 2,
    position: [-0.1, 1, 5] as [number, number, number], // Ensure correct tuple type
  });

  const updateCameraSettings = () => {
    if (window.innerWidth < 800) {
      setCameraSettings({
        fov: 2.5,
        position: [-0.1, 1, 5] as [number, number, number], // Ensure correct tuple type
      });
    } else {
      setCameraSettings({
        fov: 1.2,
        position: [-0.1, 1, 5] as [number, number, number], // Ensure correct tuple type
      });
    }
  };

  useEffect(() => {
    updateCameraSettings();
    window.addEventListener('resize', updateCameraSettings);

    return () => {
      window.removeEventListener('resize', updateCameraSettings);
    };

  }, []);


  //  end resize


  // console.log("11111111111-------------------",selectedbackcolor)

  const [value, setValue] = useState('');




  useEffect(() => {
    // LocalStorage se value lena
    const storedValue = localStorage.getItem('myValue');
    if (storedValue) {
      setValue(storedValue);
    }
  }, []);
  // console.log('value==============>',value)





  useEffect(() => {
    if (scene) {
      // console.log("Loaded GLTF scene:", scene);
      scene.traverse((child) => {
        if (child instanceof THREE.Mesh) {
          // Set color for specific parts

          if (child.name === `Arch_0${selectedArchIndex + 1}-glass` || child.name === `grill_${selectedHandleIndex + 1}`) {
            child.visible = true;
            // child.material.color.set(`${selectedColorGetIndex}`);  
            child.material.transparent = true;
          }
          else if (child.name === `Handle_${selectedGrillIndex + 1}`) {
            child.visible = true;
            // child.material.color.set(`${selectedColorGetIndex}`);  
            child.material.transparent = true;
          } else if (child.name === `Arch_0${selectedArchIndex + 1}_glass` || child.name === `sidelight_and_transom_0${sideLightGrill + 1}`) {
            child.visible = true;
            // child.material.color.set(`${selectedColorGetIndex}`);  
            child.material.transparent = true;
          }
          else if (child.name == "frame" || child.name == "frame_back" || child.name == "door_1" || child.name == "door_2" || child.name === `Arch_0${selectedArchIndex + 1}`) {

            // else if ( child.name.includes("frame") || child.name.includes("door_1") || child.name.includes("door_2") ||child.name === `Arch_0${selectedArchIndex+1}`  ) {
            child.visible = true;
            child.material.color.set(selectedColorGetIndex ? `${selectedColorGetIndex}` : "#4f555c");
            child.material.transparent = true;
          }
          else if (child.name.includes("door_back") || child.name.includes("frame-back") || child.name.includes(`Arch_0${selectedArchIndex + 1}_back`)) {
            child.visible = true;
            child.material.color.set(selectedbackcolor ? selectedbackcolor : '#000000');
            child.material.transparent = true;
          }

          else if (
            child.name.includes("Arch_0") ||
            child.name.includes("Handle") ||
            child.name.includes("grill_") ||
            child.name.includes("sidelight_and_transom_0")



          ) {
            child.visible = false;
          }

          // Enable edge sharpening
          child.castShadow = true;
          child.receiveShadow = true;
        }
      });

      scene.position.set(0, 0, 0);
      scene.scale.set(1.5, 1.5, 1.9);
      const boundingBox = new THREE.Box3().setFromObject(scene);
      const center = boundingBox.getCenter(new THREE.Vector3());
      scene.position.set(-center.x-0.001, -center.y + 0.001, -center.z);
    }
  }, [scene, selectedArchIndex, selectedColorGetIndex, selectedHandleIndex, selectedGrillIndex, selectedbackcolor, sideLightGrill]);

  useEffect(() => {
    // Compute the bounding box and center
    const boundingBox = new THREE.Box3().setFromObject(scene);
    const center = boundingBox.getCenter(new THREE.Vector3());
    
    const cameraOffset = 5; // Set an offset for the camera
    camera.position.set(center.x, center.y + 1, center.z + cameraOffset); // Adjust as needed
    camera.lookAt(center);
  }, [camera, scene]);

  // Adjust camera based on selectedbackfront
  const backFront = Number(selectedbackfront); // Ensure comparison is done with numbers
  if (backFront === 1) {
    camera.position.set(0, 1, -5);
    camera.lookAt(0, 1, 0);
  } else if (backFront === 0) {
    camera.position.set(1, -1, 5);
    camera.lookAt(0, 0, 0);
  }

  return (
    <>
      <PerspectiveCamera makeDefault fov={cameraSettings.fov} position={cameraSettings.position} />
      <ambientLight intensity={0.5} />
      <spotLight position={[-10, 10, 10]} angle={1.15} penumbra={2} intensity={1} />
      <directionalLight position={[-2, 4, 5]} intensity={3} />
      {scene && <primitive object={scene} />} {/* Only render if scene is not null */}
      <primitive object={scene} />
      <OrbitControls />
    </>
  );
};

const MyComponent = () => {



  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 800);

  const handleResize = () => {
    setIsLargeScreen(window.innerWidth > 800);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  return (
    <StyledContainer>
      <Box style={{ width: isLargeScreen ? '95%' : 'auto', height: '100%'  }}>
      <Canvas shadows>
          <SceneContent />

          <directionalLight position={[5, 5, 5]} intensity={1.5} />
          <directionalLight position={[-5, 5, 5]} intensity={1.5} />
          <directionalLight position={[5, -5, 5]} intensity={1.5} />
          <directionalLight position={[-5, -5, 5]} intensity={1.5} />
          <directionalLight position={[0, 0, -4]} intensity={1.2} />
          <directionalLight position={[0, 5, -4]} intensity={1.2} />
          <directionalLight position={[0, -5, -5]} intensity={1.2} />

          <pointLight position={[0.5, 1, 5]} intensity={1.2} />
          <pointLight position={[-0.8, 0, 5]} intensity={1.2} />
          <pointLight position={[0.5, -1, 5]} intensity={1.2} />
          <pointLight position={[-0.5, -1, 5]} intensity={1.2} />


        </Canvas>
      </Box>
    </StyledContainer>
  );
};

export default MyComponent;
