import React, { useEffect } from "react";
import "./App.css";
import { ColorProvider } from "./componets/ColorContext";
import MiniDrawer from "./componets/MiniDrawer";

function App() {
  useEffect(() => {
    // Clear localStorage when the App component mounts (on page load)
    localStorage.clear();
  }, []);
  return (
    <ColorProvider>
      <div className="App">
        <MiniDrawer />
      </div>
    </ColorProvider>
  );
}
//Ayush
export default App;
