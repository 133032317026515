

import React from "react";
import { useColorContext } from "../ColorContext";
import { Box, Typography } from "@mui/material";
import MyComponent from "./GLTFviewer";

function Doorstyle() {
  const { selectedDoor } = useColorContext();

  const sprites = [
    "with-sidelight-and-transom",
    "with-sidelights",
    "with-simulated-divided-light",
    "with-transom",
    "with-true-divide-lights",
    "with-operable-glass",
    "double-door-sidelight-and-transom",
    "double-door-transom",
    "double-door-true-divide-lights",
    "double-door-sidelights",
    "double-door-simulated-divided-light",
    "double-door-sidelights1",
  ];

  return (
    <Box
      // sx={{
      //   // display: "flex",
      //   justifyContent: "center",
      //   alignItems: "center",
      //   height: "90vh",
      // }}
    >
      <MyComponent />
      {/* <Box sx={{ textAlign: "center" }}>
    
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          {sprites.map(
            (sprite) =>
              selectedDoor === sprite && (
                <span
                  key={sprite}
                  className={`css-sprite-${sprite}`}
                  style={{ marginTop: 0 }}
                ></span>
              )
          )}
        </Box>
      </Box> */}
    </Box>
  );
}

export default Doorstyle;
