
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { log } from 'console';
import { useEffect , useState } from 'react';

const KIT_COLOR = '#5F1C1D';
const CustomRadio = styled(Radio)({
  color: KIT_COLOR,
  '&.Mui-checked': {
    color: KIT_COLOR,
  },
});

const Micscom = ({ onSelect }: { onSelect: (component: React.ReactNode) => void }) => {

  const [trimexterior2UnitCost, setTrimexterior2UnitCost] = React.useState<any>('');
  const [trimexterior2UnitCost1, setTrimexterior2UnitCost1] = React.useState<any>('');
  const [trimexterior2UnitCost2, setTrimexterior2UnitCost2] = React.useState<any>(140);
  const [trimexterior2UnitCost3, setTrimexterior2UnitCost3] = React.useState<any>(550);

  // State for showing input fields and radio button values
  const [showInput, setShowInput] = React.useState<any>('no');
  const [showInput1, setShowInput1] = React.useState<any>('no');
  const [showInput2, setShowInput2] = React.useState<any>('no');
  const [showInput3, setShowInput3] = React.useState<any>('no');



  
  const [width, setWidth] = useState<number | null>(null);
  const [height, setHeight] = useState<number | null>(null);
  const [unitCost, setUnitCost] = useState<number | null>(null);
  const [area,setArea]= useState<number | null>(null);


  useEffect(() => {
    const storedWidth = localStorage.getItem("width");
    const storedHeight = localStorage.getItem("height");
    const storedUnitCost = localStorage.getItem("unitCost");

    if (storedWidth) setWidth(Number(storedWidth));
    if (storedHeight) setHeight(Number(storedHeight));
    if (storedUnitCost) setUnitCost(Number(storedUnitCost));
  }, []);



  useEffect(() => {

    if (width !== null && height !== null && unitCost !== null) {
     const calculatedArea = (((width * height) / 144) * unitCost); // Calculate area in square feet
     const areaValue = 0.2* calculatedArea; // Calculate 20% of the area
     setArea(areaValue);

console.log("ayush======>",areaValue)

     if(trimexterior2UnitCost == '' ){
      setTrimexterior2UnitCost(areaValue.toFixed(2)); // Update archunitcost with the calculated area
     }

     else if(trimexterior2UnitCost1 == '' ){
      setTrimexterior2UnitCost1(areaValue.toFixed(2)); // Update archunitcost with the calculated area
     }

   }

 }, [width, height, unitCost,trimexterior2UnitCost,trimexterior2UnitCost1]);





  const handleRadioChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value;
    setShowInput(selectedValue);

 
    await AsyncStorage.setItem('overunit10', selectedValue);  
 
   
  };

  const handleRadioChange1 = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value;
    setShowInput1(selectedValue);
    await AsyncStorage.setItem("overunit14",selectedValue)
    // if (selectedValue === 'no') setTrimexterior2UnitCost1(0); // Reset value to 0 if 'No' is selected
    // saveData();
  };


  const handleRadioChange2 = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value;
    setShowInput2(selectedValue);
    await AsyncStorage.setItem('bugScreen',selectedValue)
  
  };

  const handleRadioChange3 =  async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value;
    setShowInput3(selectedValue);
    await AsyncStorage.setItem('Remodal',selectedValue)
 
  };


  const handleTrimexterior2UnitCostChange =  async (event: React.ChangeEvent<HTMLInputElement>) => {
    
    
    const overvalue10 = event.target.value;
    setTrimexterior2UnitCost(parseFloat(event.target.value)  );
    await AsyncStorage.setItem('trimexterior2UnitCostMics', overvalue10 )

    
  };

  const handleTrimexterior2UnitCostChange1 =  async (event: React.ChangeEvent<HTMLInputElement>) => {

    const overunit14unitcost = event.target.value;
    setTrimexterior2UnitCost1(parseFloat(event.target.value)  );
    await AsyncStorage.setItem('overunit14unitcost', overunit14unitcost );
    // saveData();
  };

  const handleTrimexterior2UnitCostChange2 =  async (event: React.ChangeEvent<HTMLInputElement>) => {
    const BugScreenUnitCost = event.target.value;
    setTrimexterior2UnitCost2(parseFloat(event.target.value)  );
    await AsyncStorage.setItem('BugScreenUnitCost', BugScreenUnitCost );
    // saveData();
  };



  const handleTrimexterior2UnitCostChange3 =  async (event: React.ChangeEvent<HTMLInputElement>) => {
    const RemoldUnitCost = event.target.value;
    setTrimexterior2UnitCost3(parseFloat(event.target.value)  );
    await AsyncStorage.setItem('RemoldUnitCost', RemoldUnitCost );
    // saveData();
  };


 

  React.useEffect( () => {
    const loadValues = async () => {


    const overunit10 = await AsyncStorage.getItem('overunit10');
    const overunit14 = await AsyncStorage.getItem('overunit14');
    const bugScreen  = await AsyncStorage.getItem('bugScreen');
    const Remodal    = await AsyncStorage.getItem('Remodal');
    const trimexterior2UnitCost = await AsyncStorage.getItem('trimexterior2UnitCostMics');
    const overunit14unitcost = await AsyncStorage.getItem('overunit14unitcost');
    const BugScreenUnitCost = await AsyncStorage.getItem('BugScreenUnitCost');
    const RemoldUnitCost = await AsyncStorage.getItem('RemoldUnitCost');





    
 
     if (overunit10) {
      setShowInput(overunit10);
    }
     if(overunit14){
      setShowInput1(overunit14);
    }
    if(bugScreen){
      setShowInput2(bugScreen);
    }
    if(Remodal){
      setShowInput3(Remodal);
    }


    if(trimexterior2UnitCost){
      setTrimexterior2UnitCost(trimexterior2UnitCost)
    }
    if(overunit14unitcost){
      setTrimexterior2UnitCost1(overunit14unitcost)
    }
    if(BugScreenUnitCost){
      setTrimexterior2UnitCost2(BugScreenUnitCost)
    }    
    if(RemoldUnitCost){
      setTrimexterior2UnitCost3(RemoldUnitCost)
    }

     
  };

  loadValues();

  }, []);




    
  // useEffect(() => {
  //   const storeData = async () => {


      

  //     try {
  //       await AsyncStorage.setItem('trimexterior2UnitCostMics', trimexterior2UnitCost.toString());
  //       await AsyncStorage.setItem('overunit14unitcost', trimexterior2UnitCost1.toString());
  //       await AsyncStorage.setItem('BugScreenUnitCost', trimexterior2UnitCost2.toString());
  //       await AsyncStorage.setItem('RemoldUnitCost', trimexterior2UnitCost3.toString());


        
  //     } catch (error) {


  //       // Handle error
  //       console.error('Error saving data', error);
  //     }
  //   };

  //   storeData();
  // }, [trimexterior2UnitCost, trimexterior2UnitCost1, trimexterior2UnitCost2, trimexterior2UnitCost3 ]); 

 



  return (
    <div>
      <Box sx={{ p: 1 }}>
        <Typography
          variant="h6"
          align="center"
          gutterBottom
          sx={{ fontWeight: 'bold' }}
        >
          Misc
        </Typography>
        <Typography
          variant="h6"
          sx={{ backgroundColor: '#5F1C1D', color: 'white', pl: 2, pt: 0.5, pb: 0.5, mt: 2, mb: 2 }}
        >
          Over 10 Door
        </Typography>


        <FormControl sx={{ pl: 2,   }}>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={handleRadioChange} 
          value={showInput}
        >
          <FormControlLabel value="yes" control={<CustomRadio />} label="Yes" />
          <FormControlLabel value="no" control={<CustomRadio />} label="No" />
        </RadioGroup>
      </FormControl>
      <br />


      {showInput === 'yes' && (

<TextField
  label="Unit Cost"
  value={trimexterior2UnitCost}
  onChange={handleTrimexterior2UnitCostChange}
  variant="outlined"
  type="number" InputProps={{ inputProps: { min: 0 } }}
  inputProps={{ step: '1' }}
  style={{ width: '100%' }}
  sx={{
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: KIT_COLOR, // Default border color
      },
      '&:hover fieldset': {
        borderColor: KIT_COLOR, // Border color on hover
      },
      '&.Mui-focused fieldset': {
        borderColor: KIT_COLOR, // Border color when focused
      },
    },
    '& .MuiInputLabel-root': {
      color: KIT_COLOR, // Label color
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: KIT_COLOR, // Label color when focused
    },
  }}
/>


) 
}


<Typography
        variant="h6"
        sx={{ backgroundColor: '#5F1C1D', color: 'white', pl: 2, pt: 0.5, pb: 0.5, mt: 2,mb:2 }}
      >
        Over 14 Unit
      </Typography>

      <FormControl sx={{ pl: 2,   }}>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={handleRadioChange1}
          value={showInput1}
        >
          <FormControlLabel value="yes" control={<CustomRadio />} label="Yes" />
          <FormControlLabel value="no" control={<CustomRadio />} label="No" />
        </RadioGroup>
      </FormControl>
      <br />

      {showInput1 === 'yes' && (
        <TextField
          label="Unit Cost"
          value={trimexterior2UnitCost1}
          onChange={handleTrimexterior2UnitCostChange1}
          variant="outlined"
          type="number" InputProps={{ inputProps: { min: 0 } }}
          inputProps={{ step: '1' }}
          style={{ width: '100%' }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: KIT_COLOR, // Default border color
              },
              '&:hover fieldset': {
                borderColor: KIT_COLOR, // Border color on hover
              },
              '&.Mui-focused fieldset': {
                borderColor: KIT_COLOR, // Border color when focused
              },
            },
            '& .MuiInputLabel-root': {
              color: KIT_COLOR, // Label color
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: KIT_COLOR, // Label color when focused
            },
          }}
        />
      )}


<Typography
        variant="h6"
        sx={{ backgroundColor: '#5F1C1D', color: 'white', pl: 2, pt: 0.5, pb: 0.5, mt: 2,mb:2 }}
      >
        Bug Screen
      </Typography>

      <FormControl sx={{ pl: 2,   }}>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={handleRadioChange2}
          value={showInput2}
        >
          <FormControlLabel value="yes" control={<CustomRadio />} label="Yes" />
          <FormControlLabel value="no" control={<CustomRadio />} label="No" />
        </RadioGroup>
      </FormControl>
      <br />

      {showInput2 === 'yes' && (
        <TextField
          label="Unit Cost"
          value={trimexterior2UnitCost2}
          onChange={handleTrimexterior2UnitCostChange2}
          variant="outlined"
          type="number"  
          InputProps={{ inputProps: { min: 0 } }}
          inputProps={{ step: '1' }}
          style={{ width: '100%' }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: KIT_COLOR, // Default border color
              },
              '&:hover fieldset': {
                borderColor: KIT_COLOR, // Border color on hover
              },
              '&.Mui-focused fieldset': {
                borderColor: KIT_COLOR, // Border color when focused
              },
            },
            '& .MuiInputLabel-root': {
              color: KIT_COLOR, // Label color
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: KIT_COLOR, // Label color when focused
            },
          }}
        />
      )}


<Typography
        variant="h6"
        sx={{ backgroundColor: '#5F1C1D', color: 'white', pl: 2, pt: 0.5, pb: 0.5, mt: 2,mb:2 }}
      >
        Remodal
      </Typography>

      <FormControl sx={{ pl: 2,   }}>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={handleRadioChange3}
          value={showInput3}
        >
          <FormControlLabel value="yes" control={<CustomRadio />} label="Yes" />
          <FormControlLabel value="no" control={<CustomRadio />} label="No" />
        </RadioGroup>
      </FormControl>
      <br />

      {showInput3 === 'yes' && (
        <TextField
          label="Unit Cost"
          value={trimexterior2UnitCost3}
          onChange={handleTrimexterior2UnitCostChange3}
          variant="outlined"
          type="number"         
          
          InputProps={{ inputProps: { min: 0 } }}

          inputProps={{ step: '1' }}
          style={{ width: '100%' }}

          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: KIT_COLOR, // Default border color
              },
              '&:hover fieldset': {
                borderColor: KIT_COLOR, // Border color on hover
              },
              '&.Mui-focused fieldset': {
                borderColor: KIT_COLOR, // Border color when focused
              },
            },
            '& .MuiInputLabel-root': {
              color: KIT_COLOR, // Label color
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: KIT_COLOR, // Label color when focused
            },
          }}
        />
      )}


 
      </Box>

    </div>
  )
}
export default Micscom;
