import * as React from 'react';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

const KIT_COLOR = '#5F1C1D';

// Custom Radio component to apply color
const CustomRadio = styled(Radio)({
  color: KIT_COLOR,
  '&.Mui-checked': {
    color: KIT_COLOR,
  },
});

const Kitsscom = ({ onSelect }: { onSelect: (component: React.ReactNode) => void }) => {
  // Initialize state
  const [trimenterior1RadioValue, setTrimenterior1RadioValue] = React.useState<string>(() => localStorage.getItem('trimenterior1RadioValue') || 'No');
  const [trimenterior1Width, setTrimenterior1Width] = React.useState<number>(() => Number(localStorage.getItem('trimenterior1Width')) ||  0);
  const [trimenterior1Height, setTrimenterior1Height] = React.useState<number>(() => Number(localStorage.getItem('trimenterior1Height')) ||  0);
  const [trimenterior1UnitCost, setTrimenterior1UnitCost] = React.useState<number>(() => Number(localStorage.getItem('trimenterior1UnitCost')) || 8);

  const [trimexterior2RadioValue, setTrimexterior2RadioValue] = React.useState<string>(() => localStorage.getItem('trimexterior2RadioValue') || 'No');
  const [trimexterior2Width, setTrimexterior2Width] = React.useState<number>(() => Number(localStorage.getItem('trimexterior2Width')) || 0);
  const [trimexterior2Height, setTrimexterior2Height] = React.useState<number>(() => Number(localStorage.getItem('trimexterior2Height')) ||  0);
  const [trimexterior2UnitCost, setTrimexterior2UnitCost] = React.useState<number>(() => Number(localStorage.getItem('trimexterior2UnitCost')) ||  8);

  const handleTrimenterior1RadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    setTrimenterior1RadioValue(value);
    localStorage.setItem('trimenterior1RadioValue', value);
  };

  const handleTrimenterior1WidthChange = (event: Event, newValue: number | number[]) => {
    const value = newValue as number;
    setTrimenterior1Width(value);
    localStorage.setItem('trimenterior1Width', value.toString());
  };

  const handleChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(event.target.value);
    if (!isNaN(newValue)) {
      setTrimenterior1Width(newValue);
      localStorage.setItem('trimenterior1Width', newValue.toString());
    }
  };

  const handleChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(event.target.value);
    if (!isNaN(newValue)) {
      setTrimenterior1Height(newValue);
      localStorage.setItem('trimenterior1Height', newValue.toString());
    }
  };

  const handleTrimenterior1HeightChange = (event: Event, newValue: number | number[]) => {
    const value = newValue as number;
    setTrimenterior1Height(value);
    localStorage.setItem('trimenterior1Height', value.toString());
  };

  const handleTrimenterior1UnitCostChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(event.target.value) || 0;
    setTrimenterior1UnitCost(newValue);
    localStorage.setItem('trimenterior1UnitCost', newValue.toString());
  };


  React.useEffect(() => {
    localStorage.setItem('trimenterior1UnitCost',trimenterior1UnitCost.toString());
  }, [trimenterior1UnitCost]); 

  React.useEffect(() => {
    localStorage.setItem('trimexterior2UnitCost',trimexterior2UnitCost.toString());
  }, [trimexterior2UnitCost]); 



  const handleTrimexterior2RadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    setTrimexterior2RadioValue(value);
    localStorage.setItem('trimexterior2RadioValue', value);
  };

  const handleTrimexterior2WidthChange = (event: Event, newValue: number | number[]) => {
    const value = newValue as number;
    setTrimexterior2Width(value);
    localStorage.setItem('trimexterior2Width', value.toString());
  };

  const handleChange4 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(event.target.value);
    if (!isNaN(newValue)) {
      setTrimexterior2Width(newValue);
      localStorage.setItem('trimexterior2Width', newValue.toString());
    }
  };

  const handleChange3 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(event.target.value);
    if (!isNaN(newValue)) {
      setTrimexterior2Height(newValue);
      localStorage.setItem('trimexterior2Height', newValue.toString());
    }
  };

  const handleTrimexterior2HeightChange = (event: Event, newValue: number | number[]) => {
    const value = newValue as number;
    setTrimexterior2Height(value);
    localStorage.setItem('trimexterior2Height', value.toString());
  };

  const handleTrimexterior2UnitCostChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(event.target.value) || 0;
    setTrimexterior2UnitCost(newValue);
    localStorage.setItem('trimexterior2UnitCost', newValue.toString());
  };

  function calculateValue(width: number, height: number) {
    return (width + height+ height) / 12;
  }


  const handleOptionClick = (option: string) => {
    console.log(`Option clicked: ${option}`);
    onSelect(option);  // Example usage, if `onSelect` is a prop function 
  };

 


  return (
    <Box sx={{p:1}}>
      {/* Trimenterior1 Button */}
      <Typography
        variant="h6"
        align="center"
        gutterBottom
        sx={{ fontWeight: 'bold' }}
      >
     Kits
      </Typography>
      <Box>
      <Typography
        variant="h6"
        sx={{ backgroundColor: '#5F1C1D', color: 'white', pl: 2, pt: 0.5, pb: 0.5, mt: 2,mb:2 }}
      >
        Trim Exterior
      </Typography>
        <Box display="flex" alignItems="center" mb={1}>
 
        </Box>
        <Box
          sx={{
            borderRadius: 2,
            backgroundColor: '#f9f9f9',
            padding: 2,
          }}
        >
          <FormControl component="fieldset"> 
            <RadioGroup
              aria-label="trim-interior-trimenterior1"
              name="trim-interior-trimenterior1"
              value={trimenterior1RadioValue}
              onChange={handleTrimenterior1RadioChange}
            >
              <Box>
                <FormControlLabel
                  value="Yes"
                  control={<CustomRadio />}
                  label="Yes"
                />
                <FormControlLabel
                  value="No"
                  control={<CustomRadio />}
                  label="No"
                />
              </Box>
            </RadioGroup>

            {trimenterior1RadioValue === 'Yes' && (
              <Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', justifyContent: 'center' }}>
                 <Typography>Width</Typography>
                  <input
                    type="number" min="0"
                    id="textInput"
                    value={trimenterior1Width}
                    max={1110}
                    onChange={handleChange1}
                    style={{
                      textAlign: 'center',
                      width: '80px',
                      marginRight: '8px',
                      fontWeight: 'bold',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      height: '35px',
                      fontSize: '16px'
                    }}
                  />
                  <Slider
                    aria-label="Width"
                    value={trimenterior1Width}
                    onChange={handleTrimenterior1WidthChange}
                    getAriaValueText={(value:any) => `${value} units`}
                    step={1}
                    min={0}
                    max={1110}
                    sx={{ color: KIT_COLOR }}
                  />
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', justifyContent: 'center' }}>
                <Typography>Height</Typography>
                 
                  <input
                    type="number" min="0"
                    id="textInput"
                    value={trimenterior1Height}
                    max={1110}
                    onChange={handleChange2}
                    style={{
                      textAlign: 'center',
                      width: '80px',
                      marginRight: '8px',
                      fontWeight: 'bold',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      height: '35px',
                      fontSize: '16px'
                    }}
                  />
                  <Slider
                    aria-label="Height"
                    value={trimenterior1Height}
                    onChange={handleTrimenterior1HeightChange}
                    getAriaValueText={(value:any) => `${value} units`}
                    step={1}
                    min={0}
                    max={1110}
                    sx={{ color: KIT_COLOR }}
                  />
                </Box>

                <Box mt={2} display="flex" alignItems="center">
                  <TextField
                    label="Sqr/ft"
                    value={calculateValue(trimenterior1Width, trimenterior1Height).toFixed(2)}
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                    style={{ marginRight: 16 }}
                    InputLabelProps={{ style: { color: KIT_COLOR } }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'defaultBorderColor', // Default border color
                        },
                        '&:hover fieldset': {
                          borderColor: 'defaultBorderColor', // Border color on hover
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: KIT_COLOR, // Border color when focused
                        },
                      },
                    }}
                  />
                  <TextField
                    label="Unit Cost"
                    value={trimenterior1UnitCost}
                    onChange={handleTrimenterior1UnitCostChange}
                    variant="outlined"
                    type="number"  InputProps={{ inputProps: { min: 0 } }}
                    inputProps={{ step: '0.01' }}
                    InputLabelProps={{ style: { color: KIT_COLOR } }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'defaultBorderColor', // Default border color
                        },
                        '&:hover fieldset': {
                          borderColor: 'defaultBorderColor', // Border color on hover
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: KIT_COLOR, // Border color when focused
                        },
                      },
                    }}
                  />
                </Box>
              </Box>
            )}
          </FormControl>
        </Box>
      </Box>

      <Box mt={2}>


      <Typography
        variant="h6"
        sx={{ backgroundColor: '#5F1C1D', color: 'white', pl: 2, pt: 0.5, pb: 0.5, mt: 2,mb:2 }}
      >
        Trim Interior
      </Typography> 
      
        <Box display="flex" alignItems="center" mb={1}>
 
        </Box>
        <Box
          sx={{
            borderRadius: 2,
            backgroundColor: '#f9f9f9',
            padding: 2,
          }}
        >
          <FormControl component="fieldset">
 
            <RadioGroup
              aria-label="trim-interior-trimexterior2"
              name="trim-interior-trimexterior2"
              value={trimexterior2RadioValue}
              onChange={handleTrimexterior2RadioChange}
            >
              <Box>
                <FormControlLabel
                  value="Yes"
                  control={<CustomRadio />}
                  label="Yes"
                />
                <FormControlLabel
                  value="No"
                  control={<CustomRadio />}
                  label="No"
                />
              </Box>
            </RadioGroup>

            {trimexterior2RadioValue === 'Yes' && (
              <Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', justifyContent: 'center' }}>
                <Typography>Width</Typography>
                  <input
                    type="number" min="0"
                    id="textInput"
                    value={trimexterior2Width}
                    max={1110}
                    onChange={handleChange4}
                    style={{
                      textAlign: 'center',
                      width: '80px',
                      marginRight: '8px',
                      fontWeight: 'bold',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      height: '35px',
                      fontSize: '16px'
                    }}
                  />
                  <Slider
                    aria-label="Width"
                    value={trimexterior2Width}
                    onChange={handleTrimexterior2WidthChange}
                    getAriaValueText={(value:any) => `${value} units`}
                    step={1}
                    min={0}
                    max={1110}
                    sx={{ color: KIT_COLOR }}
                  />
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', justifyContent: 'center' }}>
                <Typography>Height</Typography>
                 
                  <input
                    type="number" min="0"
                    id="textInput"
                    value={trimexterior2Height}
                    max={1110}
                    onChange={handleChange3}
                    style={{
                      textAlign: 'center',
                      width: '80px',
                      marginRight: '8px',
                      fontWeight: 'bold',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      height: '35px',
                      fontSize: '16px'
                    }}
                  />
                  <Slider
                    aria-label="Height"
                    value={trimexterior2Height}
                    onChange={handleTrimexterior2HeightChange}
                    getAriaValueText={(value:any) => `${value} units`}
                    step={1}
                    min={0}
                    max={1110}
                    sx={{ color: KIT_COLOR }}
                  />
                </Box>

                <Box mt={2} display="flex" alignItems="center">
                  <TextField
                    label="Sqr/ft"
                    value={calculateValue(trimexterior2Width, trimexterior2Height).toFixed(2)}
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                    style={{ marginRight: 16 }}
                    InputLabelProps={{ style: { color: KIT_COLOR } }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'defaultBorderColor', // Default border color
                        },
                        '&:hover fieldset': {
                          borderColor: 'defaultBorderColor', // Border color on hover
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: KIT_COLOR, // Border color when focused
                        },
                      },
                    }}
                  />
                  <TextField
                    label="Unit Cost"
                    value={trimexterior2UnitCost}
                    onChange={handleTrimexterior2UnitCostChange}
                    variant="outlined"
                    type="number" InputProps={{ inputProps: { min: 0 } }}
                    inputProps={{ step: '0.01' }}
                    InputLabelProps={{ style: { color: KIT_COLOR } }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'defaultBorderColor', // Default border color
                        },
                        '&:hover fieldset': {
                          borderColor: 'defaultBorderColor', // Border color on hover
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: KIT_COLOR, // Border color when focused
                        },
                      },
                    }}
                  />
                </Box>
              </Box>
            )}
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};

export default Kitsscom;
