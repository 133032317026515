import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Deadbolt from "./Deadbolt";
import Hardwareprep from "./Hardwareprep";
import Rollerlatches from "./Rollerlatches";
import Pullhandles from "./Pullhandles";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import AsyncStorage from "@react-native-async-storage/async-storage";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { KayakingRounded } from "@mui/icons-material";
import { useEffect } from "react";

const KIT_COLOR = "#5F1C1D";
const CustomRadio = styled(Radio)({
  color: KIT_COLOR,
  "&.Mui-checked": {
    color: KIT_COLOR,
  },
});

const Fittingcom = ({
  onSelect,
}: {
  onSelect: (component: React.ReactNode) => void;
}) => {
  const [showfittingInput1, setShowfittingInput1] =
    React.useState<boolean>(false);

  const [showfittingInput3, setShowfittingInput3] =
    React.useState<boolean>(false);

  const handleTrimexterior2UnitCostChange2 = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = parseFloat(event.target.value) || 0;
    setFittingUnitCost2(value);
    await AsyncStorage.setItem("fittingUnitCost2", value.toString());
  };

  const handleTrimexterior2UnitCostChange3 = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = parseFloat(event.target.value) || 0;
    setFittingUnitCost3(value);
    await AsyncStorage.setItem("fittingUnitCost3", value.toString());
  };


  const [firstValue, setFirstValue] = React.useState<any | "">("");
  const [firstValue1, setFirstValue1] = React.useState<any>("");
  const [fittingUnitCost, setFittingUnitCost] = React.useState<any>(43);
  const [flushUnitCost, setFlushUnitCost] = React.useState<any>(49);
  const [fittingUnitCost1, setFittingUnitCost1] = React.useState<any>(155);
  const [fittingUnitCost2, setFittingUnitCost2] = React.useState<any>(176);
  const [fittingUnitCost3, setFittingUnitCost3] = React.useState<any>(75);
  const [hardwarepre, setHardwarepre] = React.useState<any>('');
  const [customHardwarepre, setCustomHardwarepre] = React.useState<any>('');


  console.log("~~~~~~~~~~~---.......",fittingUnitCost);
  
  


  React.useEffect(() => {
    const loadData = async () => {
      const storedCost = await AsyncStorage.getItem("fittingUnitCost");
      const storedCost1 = await AsyncStorage.getItem("fittingUnitCost1");
      const storedCost2 = await AsyncStorage.getItem("fittingUnitCost2");
      const storedCost3 = await AsyncStorage.getItem("fittingUnitCost3");
      const storedShowInput = await AsyncStorage.getItem("showfittingInput");
      const storedShowInput2 = await AsyncStorage.getItem("showfittingInput2");
      const storedFlushCost = await AsyncStorage.getItem("flushUnitCost");
      const hardwarepreset = await AsyncStorage.getItem("hardwarepre");

      if (storedCost) setFittingUnitCost(parseFloat(storedCost));
      if (storedFlushCost) setFlushUnitCost(parseFloat(storedFlushCost));
      if (storedCost1) setFittingUnitCost1(parseFloat(storedCost1));
      if (storedCost2) setFittingUnitCost2(parseFloat(storedCost2));
      if (storedCost3) setFittingUnitCost3(parseFloat(storedCost3));
      if (storedShowInput) setShowfittingInput(storedShowInput === "true");
      if (storedShowInput2) setShowfittingInput2(storedShowInput2 === "true");
      if (hardwarepreset) setHardwarepre(hardwarepreset);


      


    };

    loadData();
  }, []);

  const preOptions = [
    "N/A",
    "PULL HANDLES W/ROLLER LATCHES",
    "STANDARD HARDWARE",
    "TBD",
    "Add Custom"
  ];


  const [custom, setCustom] = React.useState<any>("");
  const [hardwareunit,setHardwareunit]  = React.useState("")as any

  const [showfittingInput, setShowfittingInput] =
    React.useState<boolean>(false);


    const [flushBold, setFlushBold] =
    React.useState<boolean>(false);

  const [showfittingInput2, setShowfittingInput2] =
    React.useState<boolean>(false);

  const [selectedBrand, setSelectedBrand] = React.useState<any | "">( '' );
  const [selectedHandle, setSelectedHandle] = React.useState<any | "">("");
  const [selectedLock, setSelectedLock] = React.useState<any | "">("");
  const [selectedFlush, setSelectedFlush] = React.useState<any | "">("");
const [flushQuantity,setFlushQuantity]= React.useState<number | "">("");

 

React.useEffect(() => {
  const loadData = async () => {
    const storedShowInput2 = await AsyncStorage.getItem("showfittingInput2");
    const storedShowInput = await AsyncStorage.getItem("showfittingInput");
    const storedFlushInput = await AsyncStorage.getItem("setFlushBold");
    if (storedShowInput2) setShowfittingInput2(storedShowInput2 === "true");
    if (storedShowInput) setShowfittingInput(storedShowInput === "true"); 
    if (storedFlushInput) setFlushBold(storedFlushInput === "true"); 
  };

  loadData();
}, []);

  const updateAsyncStorage = async () => {
    await AsyncStorage.setItem("fittingUnitCost", fittingUnitCost.toString());
    await AsyncStorage.setItem("fittingUnitCost1", fittingUnitCost1.toString());
    await AsyncStorage.setItem("fittingUnitCost2", fittingUnitCost2.toString());
    await AsyncStorage.setItem("fittingUnitCost3", fittingUnitCost3.toString());
    await AsyncStorage.setItem("flushUnitCost", flushUnitCost.toString());
    await AsyncStorage.setItem("showfittingInput",JSON.stringify(showfittingInput));
    await AsyncStorage.setItem("showfittingInput2",JSON.stringify(showfittingInput2));
    await AsyncStorage.setItem("firstValue", firstValue.toString());
    // await AsyncStorage.setItem("firstValue1", firstValue1.toString());
  };

  const handleFirstChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target.value);
    await AsyncStorage.setItem("firstValue",value);
    setFirstValue(value);
    // updateAsyncStorage();
  };

  const handleFirstChange1 = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    await AsyncStorage.setItem("firstValue1", value);
    setFirstValue1(value ? Number(value) : "");
    updateAsyncStorage();
 
  };

  const handleTrimexterior2UnitCostChange = async (event: React.ChangeEvent<HTMLInputElement>) => {

     const valuee = (event.target.value)  ;
    setFittingUnitCost(valuee);
    await AsyncStorage.setItem("fittingUnitCost",valuee)
   };

  

    // useEffect(() => {
    //   // if(fittingUnitCost === 43){
    //    const storeData = async () => {
    //     try {
    //       await AsyncStorage.setItem('fittingUnitCost', fittingUnitCost.toString());
    //     } catch (error) {
    //       // Handle error
    //       console.error('Error saving data', error);
    //     }
    //   }
      
    //   storeData();
    // // };
    // }, []); 
  


 


 
  const handleFlushCostChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = parseFloat(event.target.value) || 0;
    setFlushUnitCost(value);
     
    updateAsyncStorage();
    await AsyncStorage.setItem("flushUnitCost", value.toString()); 
    // updateAsyncStorage();
  };


  const handleTrimexterior2UnitCostChange1 = (event: any) => {
    const value = parseFloat(event.target.value) || 0;
    setFittingUnitCost1(value);
    updateAsyncStorage();
  };

  const handleRadioChange = async (event: any) => {
    const selectedValue = event.target.value === "yes";
    setShowfittingInput(selectedValue);
    await AsyncStorage.setItem("showfittingInput",JSON.stringify(selectedValue));
    // updateAsyncStorage();
  };


  const handleRadioFlushChange = async (event: any) => {
    const selectedValue = event.target.value === "yes";
    setFlushBold(selectedValue);
     await AsyncStorage.setItem("setFlushBold",JSON.stringify(selectedValue));
    updateAsyncStorage();
  };

  const handleRadioChange2 = async (event: any) => {
    const selectedValue = event.target.value === "yes";
    setShowfittingInput2(selectedValue);
    await AsyncStorage.setItem( "showfittingInput2", JSON.stringify(selectedValue)); 
  };




  const handleBrandChange = async (event: SelectChangeEvent<any>) => {
    const value = event.target.value;
    setSelectedBrand(value);
    await AsyncStorage.setItem("selectedBrand", value);
  };

  const handleFlushChange = async (event: SelectChangeEvent<any>) => {
    const value = event.target.value;
    setSelectedFlush(value);
    await AsyncStorage.setItem("selectedFlush", value);
  };

  const handleHandleChange = async (event: SelectChangeEvent<any>) => {
    const value = event.target.value;
    setSelectedHandle(value);
    await AsyncStorage.setItem("selectedHandle", value);
  };

  const handleLockChange = async (event: SelectChangeEvent<any>) => {
    const value = event.target.value;
    setSelectedLock(value);
    await AsyncStorage.setItem("selectedLock", value);
  };

  ////////////////  quantity     /////////////////////

  const [quantity, setQuantity] = React.useState<number | "">("");

  React.useEffect(() => {
    const loadData = async () => {
      const storedQuantity = await AsyncStorage.getItem("quantity");
      const storedFlushQuantity = await AsyncStorage.getItem("flushQuantity");
      if (storedQuantity) setQuantity(parseInt(storedQuantity, 10)); 
      if (storedFlushQuantity) setFlushQuantity(parseInt(storedFlushQuantity)); 
    };

    loadData();
  }, []);

  const handleQuantityChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = parseInt(event.target.value, 10) || 0; 
    setQuantity(value);
    await AsyncStorage.setItem("quantity", value.toString()); 

  };


  const handleFlushQuantityChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = parseInt(event.target.value, 10) || 0; 
    setFlushQuantity(value);
    await AsyncStorage.setItem("flushQuantity", value.toString()); 
    // updateAsyncStorage();
  };

  
  // const handleDepthChange = async (
  //   event: SelectChangeEvent<any | number>
  // ) => {
  //   const newDepth = event.target.value as number | any;
  //   setHardwareunit(newDepth)
  //   if (newDepth === "Add Custom") {
  //     setHardwarepre(newDepth);
  //   } else {
  //     setHardwarepre(newDepth);
  //     setCustom("");
  //     await AsyncStorage.setItem("hardwarepre", newDepth.toString());
  //   }
  // };


  const handleDepthChange = async (
    event: SelectChangeEvent<any | number>
  ) => {
    const value = event.target.value as number | any;
    setHardwareunit(value)
    setHardwarepre(value);
    await AsyncStorage.setItem("hardwarepre", value.toString());
  };




  // Local storage se value get karna
  React.useEffect(() => {
const dataset = async ()=>{
    const storedValue = await AsyncStorage.getItem('customHardwarepre'); 
    if (storedValue) {
      setCustomHardwarepre(storedValue);
    }
}
dataset();
  }, []);

  // Local storage me value set karna jab inputValue change ho
  React.useEffect(() => {
    const dsata = async ()=>{
    await AsyncStorage.setItem('customHardwarepre', customHardwarepre);
    }
    dsata();
   }, [customHardwarepre]);


   React.useEffect(() => {
    const loadData = async () => {
      const storedCost = await AsyncStorage.getItem("fittingUnitCost");
      const storedCost1 = await AsyncStorage.getItem("fittingUnitCost1");
      const storedCost2 = await AsyncStorage.getItem("fittingUnitCost2");
      const storedCost3 = await AsyncStorage.getItem("fittingUnitCost3");
      const storedShowInput = await AsyncStorage.getItem("showfittingInput");
      const storedShowInput2 = await AsyncStorage.getItem("showfittingInput2");
      const storedFirstValue = await AsyncStorage.getItem("firstValue");
      const storedFirstValue1 = await AsyncStorage.getItem("firstValue1");
      const storedBrand = await AsyncStorage.getItem("selectedBrand");
      const storedHandle = await AsyncStorage.getItem("selectedHandle");
      const storedLock = await AsyncStorage.getItem("selectedLock");
      const storedFlushbold= await AsyncStorage.getItem("setFlushBold");
      const storedFlush= await AsyncStorage.getItem("selectedFlush");
      const storedFlushQuantity = await AsyncStorage.getItem("flushQuantity");
 
      if (storedFlushQuantity) setFlushQuantity(parseInt(storedFlushQuantity)); 
      if (storedFlush) setSelectedFlush(storedFlush);
      if (storedBrand) setSelectedBrand(storedBrand);
      if (storedHandle) setSelectedHandle(storedHandle);
      if (storedLock) setSelectedLock(storedLock);
      if (storedCost) setFittingUnitCost(parseFloat(storedCost));
      if (storedCost1) setFittingUnitCost1(parseFloat(storedCost1));
      if (storedCost2) setFittingUnitCost2(parseFloat(storedCost2));
      if (storedCost3) setFittingUnitCost3(parseFloat(storedCost3));
      if (storedShowInput) setShowfittingInput(storedShowInput === "true");
      if (storedShowInput2) setShowfittingInput2(storedShowInput2 === "true");
      if (storedFlushbold) setFlushBold(storedFlushbold === "true");
      if (storedFirstValue) setFirstValue(parseFloat(storedFirstValue));
      if (storedFirstValue1) setFirstValue1(storedFirstValue1);
     };

    loadData();
  }, []);


  return (
    <Box sx={{ p: 1 }}>
      <Typography
        variant="h6"
        align="center"
        gutterBottom
        sx={{ fontWeight: "bold" }}
      >
        Fitting
      </Typography>
      <Typography
        variant="h6"
        sx={{
          backgroundColor: "#5F1C1D",
          color: "white",
          pl: 2,
          pt: 0.5,
          pb: 0.5,
          mt: 2,
          mb: 2,
        }}
      >
        Roller Latches Prep
      </Typography>

      <FormControl sx={{ pl: 2, mt: 2 }}>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={handleRadioChange}
          // defaultValue="no"
          value={showfittingInput ? "yes" : "no"}
        >
          <FormControlLabel value="yes" control={<CustomRadio />} label="Yes" />
          <FormControlLabel value="no" control={<CustomRadio />} label="No" />
        </RadioGroup>
      </FormControl>
      <br />

      {showfittingInput && (
        <>
          <Typography>Item</Typography>

          <Select
            displayEmpty
            value={selectedBrand}
            onChange={handleBrandChange}
            style={{ width: "100%" }}
          >
            <MenuItem value="">Select Brand</MenuItem>
            <MenuItem value="'EMTEK">EMTEK</MenuItem>
            <MenuItem value="'DELTANA">DELTANA</MenuItem>
          </Select>
          <br />
          <br />

          <TextField
            variant="outlined"
            label="Quantity"
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
            value={quantity}
            onChange={handleQuantityChange}
            style={{ width: "100%" }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: KIT_COLOR,
                },
                "&:hover fieldset": {
                  borderColor: KIT_COLOR,
                },
                "&.Mui-focused fieldset": {
                  borderColor: KIT_COLOR,
                },
              },
              "& .MuiInputLabel-root": {
                color: KIT_COLOR,
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: KIT_COLOR,
              },
            }}
          />

          <br />
          <br />
          <TextField
            label="Unit Cost"
            value={fittingUnitCost}
            onChange={handleTrimexterior2UnitCostChange}
            variant="outlined"
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
            style={{ width: "100%" }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: KIT_COLOR,
                },
                "&:hover fieldset": {
                  borderColor: KIT_COLOR,
                },
                "&.Mui-focused fieldset": {
                  borderColor: KIT_COLOR,
                },
              },
              "& .MuiInputLabel-root": {
                color: KIT_COLOR,
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: KIT_COLOR,
              },
            }}
          />
        </>
      )}





<Typography
        variant="h6"
        sx={{
          backgroundColor: "#5F1C1D",
          color: "white",
          pl: 2,
          pt: 0.5,
          pb: 0.5,
          mt: 2,
          mb: 2,
        }}
      >
       Flush Bolt
      </Typography>



      <FormControl sx={{ pl: 2, mt: 2 }}>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={handleRadioFlushChange}
          // defaultValue="no"
          value={flushBold ? "yes" : "no"}
        >
          <FormControlLabel value="yes" control={<CustomRadio />} label="Yes" />
          <FormControlLabel value="no" control={<CustomRadio />} label="No" />
        </RadioGroup>
      </FormControl>



      {flushBold && (
        <>
          <Typography>Item</Typography>

          <Select
            displayEmpty
            value={selectedFlush}
            onChange={handleFlushChange}
            style={{ width: "100%" }}
          >
            <MenuItem value="">Select Brand</MenuItem>
            <MenuItem value="DEL 18EFA">DEL 18EFA</MenuItem>
            <MenuItem value="DEL 12EFA">DEL 12EFA</MenuItem>
            <MenuItem value="DEL 12EFBZ">DEL 12EFBZ</MenuItem>
            <MenuItem value="TBD">TBD</MenuItem>
          </Select>
          <br />
          <br />

           <TextField
            variant="outlined"
            label="Quantity"
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
            value={flushQuantity}
            onChange={handleFlushQuantityChange}
            style={{ width: "100%" }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: KIT_COLOR,
                },
                "&:hover fieldset": {
                  borderColor: KIT_COLOR,
                },
                "&.Mui-focused fieldset": {
                  borderColor: KIT_COLOR,
                },
              },
              "& .MuiInputLabel-root": {
                color: KIT_COLOR,
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: KIT_COLOR,
              },
            }}
          />

          <br />
          <br />
          <TextField
            label="Unit Cost"
            value={flushUnitCost}
            onChange={handleFlushCostChange}
            variant="outlined"
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
            style={{ width: "100%" }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: KIT_COLOR,
                },
                "&:hover fieldset": {
                  borderColor: KIT_COLOR,
                },
                "&.Mui-focused fieldset": {
                  borderColor: KIT_COLOR,
                },
              },
              "& .MuiInputLabel-root": {
                color: KIT_COLOR,
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: KIT_COLOR,
              },
            }}
          />
        </>
      )}



      

      <Typography
        variant="h6"
        sx={{
          backgroundColor: "#5F1C1D",
          color: "white",
          pl: 2,
          pt: 0.5,
          pb: 0.5,
          mt: 2,
          mb: 2,
        }}
      >
        Pull Handles
      </Typography>

      <TextField
        // label="Unit Cost"
        variant="outlined"
        type="number"
        InputProps={{ inputProps: { min: 0 } }}
        inputProps={{ step: "1" }}
        style={{ width: "100%" }}
        value={firstValue}
        onChange={handleFirstChange}
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: KIT_COLOR, 
            },
            "&:hover fieldset": {
              borderColor: KIT_COLOR, 
            },
            "&.Mui-focused fieldset": {
              borderColor: KIT_COLOR, 
            },
          },
          "& .MuiInputLabel-root": {
            color: KIT_COLOR, 
          },
          "& .MuiInputLabel-root.Mui-focused": {
            color: KIT_COLOR, 
          },
          mt: 3,
        }}
      />

      {/* </FormControl> */}
      <br />
      <br />

      {Number(firstValue) > 0 && (
        <>
          <Typography>Type of Handles</Typography>

          <Select
            displayEmpty
            value={selectedHandle}
            onChange={handleHandleChange}
            style={{ width: "100%" }}
          >
            <MenuItem value="">Select Handle</MenuItem>
            <MenuItem value="AW44">AW44</MenuItem>
            <MenuItem value="AW45">AW45</MenuItem>
            <MenuItem value="AW46">AW46</MenuItem>
            <MenuItem value="TBD">TBD</MenuItem>
          </Select>

          <br />
          <br />

          <TextField
            label="Unit Cost"
            value={fittingUnitCost1}
            onChange={handleTrimexterior2UnitCostChange1}
            variant="outlined"
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
            inputProps={{ step: "1" }}
            style={{ width: "100%" }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: KIT_COLOR, 
                },
                "&:hover fieldset": {
                  borderColor: KIT_COLOR, 
                },
                "&.Mui-focused fieldset": {
                  borderColor: KIT_COLOR,
                },
              },
              "& .MuiInputLabel-root": {
                color: KIT_COLOR, 
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: KIT_COLOR, 
              },
            }}
          />
        </>
      )}

      <Typography
        variant="h6"
        sx={{
          backgroundColor: "#5F1C1D",
          color: "white",
          pl: 2,
          pt: 0.5,
          pb: 0.5,
          mt: 2,
          mb: 2,
        }}
      >
        Hardware Prep Type
      </Typography>

      {/* <FormControl sx={{ pl: 2, mt: 2 }}>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={handleRadioChange2}
          // defaultValue={"no"}
          value={showfittingInput2 ? "yes" : "no"}
        >
          <FormControlLabel value="yes" control={<CustomRadio />} label="Yes" />
          <FormControlLabel value="no" control={<CustomRadio />} label="No" />
        </RadioGroup>
      </FormControl>
      <br /> */}

      {/* {showfittingInput2 && ( */}
      <>
        <Select
          value={hardwarepre}
          onChange={handleDepthChange}
          displayEmpty
          inputProps={{ "aria-label": "Select Prep Type" }}
          fullWidth
        >
          <MenuItem disabled value="">
            Hardware Prep Type
          </MenuItem>
          {preOptions.map((option, index) => (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          ))}
          {/* <MenuItem value="custom">Add Custom </MenuItem> */}
        </Select>
        <br />
        {(hardwarepre === "Add Custom"  ) && (
          <TextField
            label="Custom"
             fullWidth
            value={customHardwarepre}
            onChange={(e) => setCustomHardwarepre(e.target.value)}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: KIT_COLOR, // Default border color
                },
                "&:hover fieldset": {
                  borderColor: KIT_COLOR, // Border color on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: KIT_COLOR, // Border color when focused
                },
              },
              "& .MuiInputLabel-root": {
                color: KIT_COLOR, // Label color
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: KIT_COLOR, // Label color when focused
              },
              mt: 2,
            }}
 
          />
        )}
        <br />
        <br />
       
    {hardwarepre && ( 
      <TextField
          label="Unit Cost"
          value={fittingUnitCost2}
          onChange={handleTrimexterior2UnitCostChange2}
          variant="outlined"
          type="number"
          InputProps={{ inputProps: { min: 0 } }}
          style={{ width: "100%" }}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: KIT_COLOR, 
              },
              "&:hover fieldset": {
                borderColor: KIT_COLOR, 
              },
              "&.Mui-focused fieldset": {
                borderColor: KIT_COLOR, 
              },
            },
            "& .MuiInputLabel-root": {
              color: KIT_COLOR, 
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: KIT_COLOR, 
            },
          }}
        />)}
       
      </>
      {/* )} */}

      <Typography
        variant="h6"
        sx={{
          backgroundColor: "#5F1C1D",
          color: "white",
          pl: 2,
          pt: 0.5,
          pb: 0.5,
          mt: 2,
          mb: 2,
        }}
      >
        Deadbolt Installation
      </Typography>

      <TextField
        // label="Unit Cost"
        variant="outlined"
        type="number"
        InputProps={{ inputProps: { min: 0 } }}
        inputProps={{ step: "1" }}
        style={{ width: "100%" }}
        value={firstValue1}
        onChange={handleFirstChange1}
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: KIT_COLOR, 
            },
            "&:hover fieldset": {
              borderColor: KIT_COLOR, 
            },
            "&.Mui-focused fieldset": {
              borderColor: KIT_COLOR, 
            },
          },
          "& .MuiInputLabel-root": {
            color: KIT_COLOR, // Label color
          },
          "& .MuiInputLabel-root.Mui-focused": {
            color: KIT_COLOR, 
          },
          mt: 3,
        }}
      />

      {/* </FormControl> */}
      <br />
      <br />

      {Number(firstValue1) > 0 && (
        <>
          <Typography>Type of Lock</Typography>

          <Select
            displayEmpty
            value={selectedLock}
            onChange={handleLockChange}
            style={{ width: "100%" }}
          >
            <MenuItem value="">Select Lock</MenuItem>
            <MenuItem value="'AW44">EMTEK 8455 OR EQUAL</MenuItem>
          </Select>

          <br />
          <br />

          <TextField
            label="Unit Cost"
            value={fittingUnitCost3}
            onChange={handleTrimexterior2UnitCostChange3}
            variant="outlined"
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
            inputProps={{ step: "1" }}
            style={{ width: "100%" }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: KIT_COLOR, 
                },
                "&:hover fieldset": {
                  borderColor: KIT_COLOR, 
                },
                "&.Mui-focused fieldset": {
                  borderColor: KIT_COLOR, 
                },
              },
              "& .MuiInputLabel-root": {
                color: KIT_COLOR, 
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: KIT_COLOR, 
              },
            }}
          />
        </>
      )}

   
    </Box>
  );
};
export default Fittingcom;
