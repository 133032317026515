import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import PersonIcon from "@mui/icons-material/Person";
import HomeIcon from "@mui/icons-material/Home";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import MapIcon from "@mui/icons-material/Map";
import CodeIcon from "@mui/icons-material/Code";
import PhoneIcon from "@mui/icons-material/Phone";
import CellTowerIcon from "@mui/icons-material/CellTower";
import WorkIcon from "@mui/icons-material/Work";
import TextInput from "./TextInput";
import AsyncStorage from "@react-native-async-storage/async-storage"; // For React Native
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import jsPDF from "jspdf";
import "./style.css";
import { Box, Typography, TextField, Button, Grid, InputAdornment } from '@mui/material';
import { Person, Home, LocationCity, Build, Phone, Work } from '@mui/icons-material';



function createData(
  CATEGEORY: any,
  SELCTIONS: any,
  WIDTH: any,
  HEIGHT: any,
  SQFTLFT: any,
  UNITCOST: any,
  ESTIMATEDCOST: any,
  PRICE: any,
) {
  return { CATEGEORY, SELCTIONS, WIDTH, HEIGHT, SQFTLFT, UNITCOST, ESTIMATEDCOST, PRICE };
}



// Define the shape of your form data
interface FormData {
  firstName: any;
  lastName: any;
  address: any;
  city: any;
  state: any;
  zipCode: any;
  yearBuilt: any;
  phone: any;
  cell: any;
  work: any;
}

// Define the shape of your error messages
interface Errors {
  firstName?: any;
  lastName?: any;
  address?: any;
  city?: any;
  state?: any;
  zipCode?: any;
  phone?: any;
  cell?: any;
}


export default function Enquiryexport() {
  ////////////////   door
  const [width, setWidth] = useState<number | null>(null);
  const [height, setHeight] = useState<number | null>(null);
  const [area, setArea] = useState<number | null>(null);
  const [unitCost, setUnitCost] = useState<number | null>(null);
  const [unitCost1, setUnitCost1] = useState<number | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [TransformandSlidelight, setTransformandSlidelight] = useState("");
  const [doorSwing, setstoredoorSwing] = useState("");
  const [hardwareType, sethardwareType] = useState("") as any;




  // useEffect(()=>{
  const Area = area || 9;
  const UnitCost = unitCost || 67;
  const Estimatedcost = Area * UnitCost;
  console.log("Estimatedcost", Estimatedcost);
  const Price = Estimatedcost * 2.5;
  const EstimatedCost1 = (Estimatedcost * 0.2).toFixed(2);
  console.log("-------------------------->>>>>>>>>>>>>>>", EstimatedCost1);

  // })
  useEffect(() => {
    localStorage.setItem("Estimatedcost", EstimatedCost1.toString());
  }, [EstimatedCost1]);

  console.log("============>>>1111", EstimatedCost1)



  useEffect(() => {
    const storedWidth = localStorage.getItem("width");
    const storedHeight = localStorage.getItem("height");
    const storedUnitCost = localStorage.getItem("unitCost");
    const storedUnitCost1 = localStorage.getItem("unitCost1");
    const storeTransformandSlidelight = localStorage.getItem(
      "TransformandSlidelight"
    );
    const storedoorSwing = localStorage.getItem("doorSwing");
    const storehardwareType = localStorage.getItem("hardwareType");

    if (storedWidth) setWidth(Number(storedWidth));
    if (storedHeight) setHeight(Number(storedHeight));
    if (storedUnitCost) setUnitCost(Number(storedUnitCost));
    if (storedUnitCost1) setUnitCost1(Number(storedUnitCost1));
    if (storeTransformandSlidelight) setTransformandSlidelight(String(storeTransformandSlidelight));
    if (storedoorSwing) setstoredoorSwing(String(storedoorSwing));
    if (storehardwareType) sethardwareType(String(storehardwareType));
  }, []);

  useEffect(() => {
    if (width !== null && height !== null) {
      const calculatedArea = (width * height) / 144; // Calculate area in square feet
      setArea(calculatedArea);
    }
  }, [width, height]);

  /////////////////////// door end

  //////////////////////// arch

  const [archunitcost, setArchunitcost] = useState<number | null>(null);
  const [archunitcost1, setArchunitcost1] = useState<number | null>(null);
  const [archwidth, setArchwidth] = useState<number | null>(null);
  const [archwidth1, setArchwidth1] = useState<number | null>(null);

  useEffect(() => {
    const loadValues = async () => {
      try {
        const storedArchunitcost = await AsyncStorage.getItem("archunitcost");
        const storedArchunitcost1 = await AsyncStorage.getItem("archunitcost1");
        const storedArchwidth = await AsyncStorage.getItem("archwidth");
        const storedArchwidth1 = await AsyncStorage.getItem("archwidth1");

        if (storedArchunitcost !== null)
          setArchunitcost(parseFloat(storedArchunitcost));
        if (storedArchunitcost1 !== null)
          setArchunitcost1(parseFloat(storedArchunitcost1));
        if (storedArchwidth !== null) setArchwidth(parseFloat(storedArchwidth));
        if (storedArchwidth1 !== null)
          setArchwidth1(parseFloat(storedArchwidth1));
      } catch (error) {
        console.error("Failed to load values from AsyncStorage", error);
      }
    };

    loadValues();
  }, []);

  ///////////////////////// arch door END

  ///////////////////////// JUMB STaRt

  const [jumbwidth, setJumbwidth] = useState<any | null>(null);
  const [jumbdepth, setJumbdepth] = useState<any | null>(null);
  const [jumbwidthCost, setJumbwidthCost] = useState<number | null>(null);
  const [jumbdepthCost, setJumbdepthCost] = useState<number | null>(null);

  useEffect(() => {
    const loadValues = async () => {
      const storedWidth = await AsyncStorage.getItem("jumbwidth");
      const storedDepth = await AsyncStorage.getItem("jumbdepth");
      const storedWidthCost = await AsyncStorage.getItem("jumbwidthCost");
      const storedDepthCost = await AsyncStorage.getItem("jumbdepthCost");

      if (storedWidth) setJumbwidth(storedWidth);
      if (storedDepth) setJumbdepth(storedDepth);
      if (storedWidthCost) setJumbwidthCost(parseFloat(storedWidthCost));
      if (storedDepthCost) setJumbdepthCost(parseFloat(storedDepthCost));
    };

    loadValues();
  }, []);

  ////////////////////  jumb end

  ///////////////////// installations start

  const [installationsunitcost, setInstallationsunitcost] = useState<number>(0);
  const [installationsunitcost2, setInstallationsunitcost2] =
    useState<number>(0);

  useEffect(() => {
    const loadValues = async () => {
      const storedInstallationsunitcost = await AsyncStorage.getItem(
        "installationsunitcost"
      );
      const storedInstallationsunitcost2 = await AsyncStorage.getItem(
        "installationsunitcost2"
      );

      if (storedInstallationsunitcost) {
        setInstallationsunitcost(parseFloat(storedInstallationsunitcost));
      }
      if (storedInstallationsunitcost2) {
        setInstallationsunitcost2(parseFloat(storedInstallationsunitcost2));
      }
    };

    loadValues();
  }, []);

  ///////////////////   installation end

  /////////////////////  thresold start

  const [thresholdType, setThresholdType] = useState<any>("");
  const [thresholdHT, setThresholdHT] = useState<any>("");
  const [thresholdunitCost, setThresholdunitCost] = useState<number>(0);
  const [thresholdunitCost2, setThresholdunitCost2] = useState<number>(0);
  const [thresholdwidth, setThresholdwidth] = useState<number | "">(0);

  useEffect(() => {
    // Retrieve values from local storage
    const storedThresholdType = localStorage.getItem("thresholdType");
    const storedThresholdHT = localStorage.getItem("thresholdHT");
    const storedThresholdunitCost = localStorage.getItem("thresholdunitCost");
    const storedThresholdunitCost2 = localStorage.getItem("thresholdunitCost2");
    const storedThresholdwidth = localStorage.getItem("thresholdwidth");

    // Set state with retrieved values
    if (storedThresholdType) setThresholdType(storedThresholdType);
    if (storedThresholdHT) setThresholdHT(storedThresholdHT);
    if (storedThresholdunitCost)
      setThresholdunitCost(parseFloat(storedThresholdunitCost));
    if (storedThresholdunitCost2)
      setThresholdunitCost2(parseFloat(storedThresholdunitCost2));
    if (storedThresholdwidth)
      setThresholdwidth(parseFloat(storedThresholdwidth));
  }, []); // Empty dependency array means this runs once on mount

  ////////////// threeshold end



  //////////////////    glass start

  const [glassDepth, setGlassDepth] = useState<any | null>(null);

  useEffect(() => {
    const fetchGlassDepth = async () => {
      const storedDepth = await AsyncStorage.getItem("glassDepth");
      if (storedDepth) {
        setGlassDepth(storedDepth);
      }
    };

    fetchGlassDepth();
  }, []);

  /////////////////////   glass end

  /////////////////   start fitting

  ////////////////////start

  const [fittingData, setFittingData] = React.useState({
    fittingUnitCost: 0,
    fittingUnitCost1: 0,
    fittingUnitCost2: 0,
    fittingUnitCost3: 0,
    showInput: false,
    showInput2: false,
    firstValue: 0,
    firstValue1: 0,
  });

  React.useEffect(() => {
    const loadData = async () => {
      const storedCost = await AsyncStorage.getItem("fittingUnitCost");
      const storedCost1 = await AsyncStorage.getItem("fittingUnitCost1");
      const storedCost2 = await AsyncStorage.getItem("fittingUnitCost2");
      const storedCost3 = await AsyncStorage.getItem("fittingUnitCost3");
      const storedShowInput = await AsyncStorage.getItem("showfittingInput");
      const storedShowInput2 = await AsyncStorage.getItem("showfittingInput2");
      const firstValue = await AsyncStorage.getItem("firstValue");
      const firstValue1 = await AsyncStorage.getItem("firstValue1");

      setFittingData({
        fittingUnitCost: storedCost ? parseFloat(storedCost) : 0,
        fittingUnitCost1: storedCost1 ? parseFloat(storedCost1) : 0,
        fittingUnitCost2: storedCost2 ? parseFloat(storedCost2) : 0,
        fittingUnitCost3: storedCost3 ? parseFloat(storedCost3) : 0,
        firstValue: firstValue ? parseFloat(firstValue) : 0,
        firstValue1: firstValue1 ? parseFloat(firstValue1) : 0,
        showInput: storedShowInput === "true",
        showInput2: storedShowInput2 === "true",
      });
    };

    loadData();
  }, []);

  const [selectedBrand, setSelectedBrand] = useState<any | null>(null);
  const [selectedHandle, setSelectedHandle] = useState<any | null>(null);
  const [selectedLock, setSelectedLock] = useState<any | null>(null);
  const [doorType, setDoorType] = useState<any | null>(null);
  const [swingType, setSwingType] = useState<any | null>(null);
  const [hingeType, setHingeType] = useState<any | null>(null);
  const [hingeCost, setHingeCost] = useState<any | null>(null);
  const [archUnitTop, setArchUnitTop] = useState<any | null>(null);
  const [archUnitRise, setArchUnitRise] = useState<any | null>(null);
  const [archUnitRise1, setArchUnitRise1] = useState<any | null>(null);
  const [archDoor, setArchDoor] = useState<any | null>(null);
  const [hardwarepreest, setHardwareprest] = useState<any | null>(null);
  const [flushBolt, setFlushBolt] = useState<any | null>(null);
  const [flushItem, setFlushItem] = useState<any | null>(null);
  const [flushQuantity, setFlushQuantity] = useState<any | null>(null);
  const [flushUnitCost, setFlushUnitCost] = useState<any | null>(null);
  const [overunit10, setOverunit10] = useState<any | null>(null);
  const [overunit14, setOverunit14] = useState<any | null>(null);
  const [installation, setInstallation] = useState<any | null>(null);
  const [freightIn, setFreightIn] = useState<any | null>(null);
  const [frontColorName, setFrontColorName] = useState<any | null>(null);
  const [stileWidthThickness, setStileWidthThickness] = useState<any | null>(null);
  const [stileWidthThickness2, setStileWidthThickness2] = useState<any | null>(null);
  const [hardwarepre12, setHardwarepre12] = useState<any | null>(null);
  const [hardwarePrepType, setHardwarePrepType] = useState<any | null>(null);
  const [customHardwarepre, setCustomHardwarepre] = useState<any | null>(null);
  const [bugScreen, setBugScreen] = useState<any | null>(null);
  const [remodal, setRemodal] = useState<any | null>(null);
  const [storedTrimexterior2UnitCost3, setStoredTrimexterior2UnitCost3] = useState<any | null>(null);
  const [doorUnitCost, setDoorUnitCost] = useState<any | null>(null);
  const [doorUnit14Cost, setDoorUnit14Cost] = useState<any | null>(null);
  const [bugScreencost, setbugScreencost] = useState<any | null>(null);
  const [remodalcost, setRemodalcost] = useState<any | null>(null);
  const [estimatedcost0001, setEstimatedcost0001] = useState<any | null>(null);


  console.log("ayush=====12221>", overunit10);


  console.log()

  useEffect(() => {
    const loadData = async () => {
      const brand = await AsyncStorage.getItem("selectedBrand");
      const handle = await AsyncStorage.getItem("selectedHandle");
      const lock = await AsyncStorage.getItem("selectedLock");
      const Doortype = localStorage.getItem("myValue");
      const swingType = localStorage.getItem("doorSwing");
      const hingeType = localStorage.getItem("hingeType");
      const hingeCost = localStorage.getItem("hingeCost");
      const archUnitTop = localStorage.getItem("selectedArchImage");
      const archUnitRise = await AsyncStorage.getItem("archwidth");
      const archDoor = await AsyncStorage.getItem("selectedArchDoorImage");
      const archUnitRise1 = await AsyncStorage.getItem("archwidth1");
      const hardwarepreest = await AsyncStorage.getItem("jumbdepthCost");
      const flushBolt = await AsyncStorage.getItem("setFlushBold");
      const flushItem = await AsyncStorage.getItem("selectedFlush");
      const flushQuantity = await AsyncStorage.getItem('flushQuantity');
      const flushUnitCost = await AsyncStorage.getItem('flushUnitCost');
      const overunit10 = await AsyncStorage.getItem('overunit10');
      const overunit14 = localStorage.getItem('overunit14');
      const installation = await AsyncStorage.getItem('installationOutOfTown');
      const freightIn = await AsyncStorage.getItem('freightIn');
      const frontColorName = await AsyncStorage.getItem('frontColorName');
      const StileWidthThickness = await AsyncStorage.getItem('StileWidthThickness');
      const StileWidthThickness2 = await AsyncStorage.getItem('StileWidthThickness2');
      const hardwarepre12 = await AsyncStorage.getItem('hardwarepre12');
      const hardwarePrepType = await AsyncStorage.getItem('hardwarePrepType');
      const customHardwarepre = await AsyncStorage.getItem('customHardwarepre');
      const bugScreen = localStorage.getItem('bugScreen')
      const remodal = localStorage.getItem('Remodal')
      const storedTrimexterior2UnitCost3 = localStorage.getItem('RemoldUnitCost')
      const doorUnitCost = localStorage.getItem('trimexterior2UnitCostMics')
      const doorUnit14Cost = localStorage.getItem('overunit14unitcost')
      const bugScreencost = localStorage.getItem('BugScreenUnitCost')
      const remodalcost = localStorage.getItem('RemoldUnitCost')
      const estimatedcost0001 = localStorage.getItem('Estimatedcost');



      console.log("===============>single1", hingeType)


      setSelectedBrand(brand);
      setSelectedHandle(handle);
      setSelectedLock(lock);
      setDoorType(Doortype);
      setSwingType(swingType);
      setHingeType(hingeType);
      setHingeCost(hingeCost);
      setArchUnitTop(archUnitTop);
      setArchUnitRise(archUnitRise);
      setArchUnitRise1(archUnitRise1);
      setArchDoor(archDoor);
      setHardwareprest(hardwarepreest);
      setFlushBolt(flushBolt);
      setFlushItem(flushItem);
      setFlushQuantity(flushQuantity);
      setFlushUnitCost(flushUnitCost);
      setOverunit10(overunit10)
      setOverunit14(overunit14)
      setInstallation(installation);
      setFreightIn(freightIn);
      setFrontColorName(frontColorName);
      setStileWidthThickness(StileWidthThickness)
      setStileWidthThickness2(StileWidthThickness2)
      setHardwarepre12(hardwarepre12);
      setHardwarePrepType(hardwarePrepType);
      setCustomHardwarepre(customHardwarepre);
      setBugScreen(bugScreen);
      setRemodal(remodal);
      setStoredTrimexterior2UnitCost3(storedTrimexterior2UnitCost3);
      setDoorUnitCost(doorUnitCost);
      setDoorUnit14Cost(doorUnit14Cost);
      setbugScreencost(bugScreencost);
      setRemodalcost(remodalcost);
      setEstimatedcost0001(estimatedcost0001);
    };

    loadData();
  }, []);





  ///////////////   quantity      ////////////////////

  const [quantity, setQuantity] = useState<number | null>(null);

  useEffect(() => {
    const loadQuantity = async () => {
      const storedQuantity = await AsyncStorage.getItem("quantity");
      if (storedQuantity) {
        setQuantity(parseInt(storedQuantity, 10));
      }
    };

    loadQuantity();
  }, []);

  /////////////////end

  //////////////////// fitting

  const [trimenterior1RadioValue, setTrimenterior1RadioValue] = useState<
    string | null
  >(null);
  const [trimenterior1Width, setTrimenterior1Width] = useState<number | null>(
    null
  );
  const [trimenterior1Height, setTrimenterior1Height] = useState<number | null>(
    null
  );
  const [trimenterior1UnitCost, setTrimenterior1UnitCost] = useState<
    number | null
  >(null);

  const [trimexterior2RadioValue, setTrimexterior2RadioValue] = React.useState<
    string | null
  >(null);
  const [trimexterior2Width, setTrimexterior2Width] = React.useState<
    number | null
  >(null);
  const [trimexterior2Height, setTrimexterior2Height] = useState<number | null>(
    null
  );
  const [trimexterior2UnitCost, setTrimexterior2UnitCost] = useState<
    number | null
  >(null);

  const hardwarepre = localStorage.getItem("hardwarepre");


  const [preptype, setPreptype] = useState<number | null>(
    null
  );
  useEffect(() => {
    setPreptype(hardwarepre as any);

  }, [])

  console.log("====================", hardwarepre)

  useEffect(() => {
    const trimenterior1RadioValue = localStorage.getItem(
      "trimenterior1RadioValue"
    );
    const trimenterior1Width = localStorage.getItem("trimenterior1Width");
    const trimenterior1Height = localStorage.getItem("trimenterior1Height");
    const trimenterior1UnitCost = localStorage.getItem("trimenterior1UnitCost");
    const trimexterior2RadioValue = localStorage.getItem(
      "trimexterior2RadioValue"
    );
    const trimexterior2Width = localStorage.getItem("trimexterior2Width");
    const trimexterior2Height = localStorage.getItem("trimexterior2Height");
    const trimexterior2UnitCost = localStorage.getItem("trimexterior2UnitCost");

    if (
      trimenterior1RadioValue &&
      trimenterior1Width &&
      trimenterior1Height &&
      trimenterior1UnitCost
    ) {
      setTrimenterior1RadioValue(trimenterior1RadioValue);
      setTrimenterior1Width(parseFloat(trimenterior1Width));
      setTrimenterior1Height(parseFloat(trimenterior1Height));
      setTrimenterior1UnitCost(parseFloat(trimenterior1UnitCost));
    }

    if (
      trimexterior2RadioValue &&
      trimexterior2Width &&
      trimexterior2Height &&
      trimexterior2UnitCost
    ) {
      setTrimexterior2RadioValue(trimexterior2RadioValue);
      setTrimexterior2Width(parseFloat(trimexterior2Width));
      setTrimexterior2Height(parseFloat(trimexterior2Height));
      setTrimexterior2UnitCost(parseFloat(trimexterior2UnitCost));
    }
  }, []);



  const [values, setValues] = React.useState({
    trimexterior2UnitCost: 0,
    trimexterior2UnitCost1: 0,
    trimexterior2UnitCost2: 0,
    storedShowInput: "No",
    storedShowInput1: "No",
    storedShowInput2: "No",
    storedShowInput3: "No",
    storedTrimexterior2UnitCost: 0,
    storedTrimexterior2UnitCost1: 0,
    storedTrimexterior2UnitCost2: 0,
    storedTrimexterior2UnitCost3: 0,
  });


  const Trim_Exterior_SQRFT = (trimenterior1Width && trimenterior1Height !== null) ? (trimenterior1Width * trimenterior1Height) / 12 : 0


  const Trim_Exterior_Estimated_cost = (trimenterior1Width && trimenterior1Height !== null && trimenterior1UnitCost !== null)
    ? (trimenterior1Width * trimenterior1Height) / 12 * trimenterior1UnitCost
    : 0

  const Trim_Exterior_price = Trim_Exterior_Estimated_cost * 2.5


  const Trim_Interior_SQRFT = (trimexterior2Width && trimexterior2Height !== null) ? (trimexterior2Width * trimexterior2Height) / 12 : 0
  const Trim_Interior_cost = trimexterior2UnitCost !== null ? Trim_Interior_SQRFT * trimexterior2UnitCost : 0
  const Trim_Interior_price = Trim_Interior_cost * 2.5


  const [estimatedCost,setEstimatedCost] = useState() as any;
  const [price,setPrice] = useState() as any; 

  const rows = [

    createData(
      "Door Type & Size",
      hardwareType,
      width,
      height,
      area?.toFixed(2),
      width && height ? unitCost : '',
      width && height ? Estimatedcost?.toFixed(2) : '',
      width && height ? Price?.toFixed(2) : ''
    ),
    createData("Door configuration", doorType ? doorType : 'Single Door', swingType, hingeType, '', hingeCost, 0, 0),

    createData("Door Transom & Side Lights", TransformandSlidelight, '', '', '', '', 0, 0),

    //  

    createData("Arch Unit", archunitcost1 !== null || archwidth !== null
      ? " Yes" : " No", archUnitTop, archUnitRise, "", archunitcost1 !== null ? `${archunitcost1}` : "0", archunitcost1 !== null ? `${archunitcost1}` : "0", archunitcost1 !== null ? archunitcost1 * 2.5 : 0),

    createData("Arch Door", archunitcost !== null || archwidth1 !== null
      ? "Yes" : "No", archDoor, archUnitRise1, "", archunitcost !== null ? `${archunitcost}` : "0", archunitcost !== null ? `${archunitcost}` : "0", archunitcost !== null ? archunitcost * 2.5 : 0),

    createData("Jamb Width", jumbwidth, "", "", "", jumbwidthCost, 0, 0),

    createData("Jamb Depth", jumbdepth == "Add Custom" ? stileWidthThickness2 : jumbdepth, "", "", "", jumbdepth  ? (jumbdepthCost ? jumbdepthCost:8):0, 0, 0),

    createData("Stile Width Thickness of door", hardwarepre12 == "Add Custom" ? stileWidthThickness : hardwarepre12, "", "", "", "", 0, 0),

    // createData("Over 10 Door", overunit10 ? "Yes" : "No", "", "", "", doorUnitCost, doorUnitCost,doorUnitCost*2),


    createData(
      "Over 10 Unit",
      overunit10 ? "Yes" : "No",
      "",
      "",
      "",

      overunit10 == "yes" ? (doorUnitCost ? doorUnitCost : EstimatedCost1) : 0,
      overunit10 == "yes" ? (doorUnitCost ? doorUnitCost : EstimatedCost1) : 0,
      overunit10 == "yes" ? ((doorUnitCost ? doorUnitCost : EstimatedCost1) * 2) : 0,


    ),

    createData(
      "Over 14 Unit",
      overunit14 ? "Yes" : "No",
      "",
      "",
      "",
      overunit14 == "yes" ? (doorUnit14Cost ? doorUnit14Cost : EstimatedCost1) : 0,
      overunit14 == "yes" ? (doorUnit14Cost ? doorUnit14Cost : EstimatedCost1) : 0,
      overunit14 == "yes" ? ((doorUnit14Cost ? doorUnit14Cost : EstimatedCost1) * 2) : 0,

    ),

    createData(
      "Roller Latches Prep", 
      fittingData.showInput ? "Yes" : "No", 
      selectedBrand, 
      quantity, 
      "", 
      fittingData.showInput   ? fittingData.fittingUnitCost : 43,  // Only check for null or undefined
      quantity !== null ? (fittingData.showInput   ? fittingData.fittingUnitCost : 43) * quantity : 0,  // Handle null values properly
      quantity !== null ? (fittingData.showInput   ? fittingData.fittingUnitCost : 43) * quantity * 2 : 0  // Handle null values properly
    ),
    


      // createData("Roller Latches Prep", fittingData.showInput ? "Yes" : "No", selectedBrand, quantity, "", fittingData.fittingUnitCost,
      //   quantity !== null ? fittingData.fittingUnitCost * quantity : '0 ', quantity !== null ? (fittingData.fittingUnitCost * quantity) * 2 : '0 '),

    createData("Flush Bolt", flushBolt ? 'Yes' : 'No', flushItem, flushQuantity, ' ', flushUnitCost, flushQuantity !== null ? flushUnitCost * flushQuantity : '0 ', flushQuantity !== null ? (flushUnitCost * flushQuantity) * 2 : '0 '),

    createData("Pull Handle", fittingData.firstValue, selectedHandle, "", "", fittingData.fittingUnitCost1, fittingData.firstValue !== null ? fittingData.firstValue * fittingData.fittingUnitCost1 : 0,
      fittingData.firstValue !== null ? (fittingData.firstValue * fittingData.fittingUnitCost1) * 2 : 0),

    createData("Hardware Prep Type", customHardwarepre ? customHardwarepre : preptype, "", "", "", fittingData.fittingUnitCost2, "", ""),

    createData("Deadbolt Installation", fittingData.firstValue1, selectedLock, "", "", fittingData.fittingUnitCost3, fittingData.firstValue1 !== null ? fittingData.firstValue1 * fittingData.fittingUnitCost3 : 0,
      fittingData.firstValue1 !== null ? (fittingData.firstValue1 * fittingData.fittingUnitCost3) * 2 : 0
    ),

    createData("Threshold Type", thresholdType ? "Yes" : "No", "", "", "", thresholdunitCost, "", ""),

    createData("Threshold HT", thresholdHT, thresholdwidth, "", "", thresholdunitCost2, "", ""),

    createData(
      "Trim Exterior",
      trimenterior1RadioValue === null ? "No" : trimenterior1RadioValue,
      trimenterior1Width === null ? "0" : trimenterior1Width,
      trimenterior1Height === null ? "0" : trimenterior1Height.toFixed(2), Trim_Exterior_SQRFT.toFixed(2),
      trimenterior1UnitCost === null ? "0" : trimenterior1UnitCost.toFixed(2), Trim_Exterior_Estimated_cost.toFixed(2), Trim_Exterior_price.toFixed(2)
    ),

    createData("Trim Interior", trimexterior2RadioValue == null ? "No" : trimexterior2RadioValue,
      trimexterior2Width == null ? "0" : trimexterior2Width.toFixed(2),
      trimexterior2Height == null ? "0" : trimexterior2Height.toFixed(2),
      Trim_Interior_SQRFT.toFixed(2), trimexterior2UnitCost == null ? "0" : trimexterior2UnitCost.toFixed(2), Trim_Interior_cost.toFixed(2), Trim_Interior_price.toFixed(2)),

    createData("Bug Screens", bugScreen, "", "", "",bugScreen?( bugScreencost?bugScreencost:140):0, bugScreen?( bugScreencost?bugScreencost:140):0, bugScreen?( bugScreencost?bugScreencost*2.5:140*2.5):0),
    
    createData("Glass Temp IG", ' ', '', '', '', '', '', ''),

    createData("Paint Color", frontColorName, '', '', '', '', '', ''),

    createData("Remodel", remodal, "", "", "",remodal?(storedTrimexterior2UnitCost3?storedTrimexterior2UnitCost3:550):0,remodal?(storedTrimexterior2UnitCost3?storedTrimexterior2UnitCost3:550):0,remodal?(storedTrimexterior2UnitCost3?storedTrimexterior2UnitCost3*2:550*2):0 ),

    createData("Installation Out of Town", installation ? "Yes" : "No", "", "", "", installationsunitcost !== null
      ? `${installationsunitcost} `
      : "0", installationsunitcost, ""),

      createData("Freight IN", freightIn ? "Yes" : "No", "", "", "", installationsunitcost2 !== null
        ? `${installationsunitcost2} `
        : "0", "", ""),

        createData( '','','','','','Total=','', ''),

  ];


  let ESTIMATEDCOST = 0;
  let PRICE = 0;
  
  // Loop through each row to calculate ESTIMATEDCOST and PRICE
  rows.forEach(row => {
    // Get the value of the 6th index (ESTIMATEDCOST) and 7th index (PRICE)
    let value = row.ESTIMATEDCOST;
    let value2 = row.PRICE;
  
    if (!isNaN(value) && value !== '') {
      ESTIMATEDCOST += parseFloat(value); // Add to the total
    }
    if (!isNaN(value2) && value2 !== '') {
      PRICE += parseFloat(value2); // Add to the total
    }
  });
  
  // Now update the last row with the calculated ESTIMATEDCOST and PRICE
  const lastRowIndex = rows.length - 1; // Get the index of the last row
  if (rows[lastRowIndex]) {
    // Directly update the keys for ESTIMATEDCOST and PRICE
    rows[lastRowIndex].ESTIMATEDCOST = ESTIMATEDCOST.toFixed(2); // Update the ESTIMATEDCOST field
    rows[lastRowIndex].PRICE = PRICE.toFixed(2); // Update the PRICE field
  }
  

 

  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalOpen1, setModalOpen1] = React.useState(false);
  const [formData, setFormData] = useState<FormData>(() => {
    const savedFormData = localStorage.getItem('formData');
    return savedFormData ? JSON.parse(savedFormData) : {
      firstName: '',
      lastName: '',
      address: '',
      city: '',
      state: '',
      zipCode: '',
      yearBuilt: '',
      phone: '',
      cell: '',
      work: '',
    };
  });

  // Effect to store the formData in localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('formData', JSON.stringify(formData));
  }, [formData]); // Trigger this effect whenever formData changes

  // Handler to update formData
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [errors, setErrors] = useState<Errors>({});

  // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setFormData({ ...formData, [e.target.name]: e.target.value });
  // };

  const validateForm = () => {
    const newErrors: Errors = {};
    if (!formData.firstName) newErrors.firstName = "First Name is required.";
    if (!formData.lastName) newErrors.lastName = "Last Name is required.";
    if (!formData.address) newErrors.address = "Address is required.";
    if (!formData.city) newErrors.city = "City is required.";
    if (!formData.state) newErrors.state = "State is required.";
    if (!formData.zipCode) newErrors.zipCode = "Zip Code is required.";
    if (!formData.phone) newErrors.phone = "Phone is required.";
    // if (!formData.cell) newErrors.cell = "Cell is required.";
    // Add more validation as needed

    return newErrors;
  };

  const handleOpenModal1 = () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);

    } else {
      setModalOpen1(true);
      setErrors({});
      // Proceed with modal opening
    }
  };





  // Function to fetch stored values from AsyncStorage
  const fetchValues = async () => {
    try {
      //Installation
      const trimexterior2UnitCost = await AsyncStorage.getItem(
        "trimexterior2UnitCost"
      );
      const trimexterior2UnitCost1 = await AsyncStorage.getItem(
        "trimexterior2UnitCost1"
      );
      const trimexterior2UnitCost2 = await AsyncStorage.getItem(
        "trimexterior2UnitCost2"
      );

      //MIsc
      const storedShowInput = await AsyncStorage.getItem("showInput");
      const storedShowInput1 = await AsyncStorage.getItem("showInput1");
      const storedShowInput2 = await AsyncStorage.getItem("showInput2");
      const storedShowInput3 = await AsyncStorage.getItem("Remodal");
      const storedTrimexterior2UnitCost = await AsyncStorage.getItem(
        "trimexterior2UnitCost"
      );
      const storedTrimexterior2UnitCost1 = await AsyncStorage.getItem(
        "overunit14unitcost"
      );
      const storedTrimexterior2UnitCost2 = await AsyncStorage.getItem(
        "BugScreenUnitCost"
      );
      const storedTrimexterior2UnitCost3 = await AsyncStorage.getItem(
        "RemoldUnitCost"
      );

      setValues({
        trimexterior2UnitCost: trimexterior2UnitCost
          ? parseFloat(trimexterior2UnitCost)
          : 0,
        trimexterior2UnitCost1: trimexterior2UnitCost1
          ? parseFloat(trimexterior2UnitCost1)
          : 0,
        trimexterior2UnitCost2: trimexterior2UnitCost2
          ? parseFloat(trimexterior2UnitCost2)
          : 0,

        //Mics set
        storedShowInput: storedShowInput ? JSON.parse(storedShowInput) : "No",
        storedShowInput1: storedShowInput1
          ? JSON.parse(storedShowInput1)
          : "No",
        storedShowInput2: storedShowInput2
          ? JSON.parse(storedShowInput2)
          : "No",
        storedShowInput3: storedShowInput3
          ? JSON.parse(storedShowInput3)
          : "No",
        storedTrimexterior2UnitCost: storedTrimexterior2UnitCost
          ? parseFloat(storedTrimexterior2UnitCost)
          : 0,
        storedTrimexterior2UnitCost1: storedTrimexterior2UnitCost1
          ? parseFloat(storedTrimexterior2UnitCost1)
          : 0,
        storedTrimexterior2UnitCost2: storedTrimexterior2UnitCost2
          ? parseFloat(storedTrimexterior2UnitCost2)
          : 0,
        storedTrimexterior2UnitCost3: storedTrimexterior2UnitCost3
          ? parseFloat(storedTrimexterior2UnitCost3)
          : 0,
      });
    } catch (error) {
      console.error("Failed to fetch stored values:", error);
    }
  };

  // Fetch values on component mount
  React.useEffect(() => {
    fetchValues();
  }, []);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  // const handleOpenModal1 = () => {
  //   setModalOpen1(true);
  // };

  const handleCloseModal1 = () => {
    setModalOpen1(false);
  };

  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setFormData({
  //     ...formData,
  //     [event.target.name]: event.target.value,
  //   });
  // };

  const handleSubmit = () => {
    console.log("Form Data:", formData);
    handleCloseModal();
  };
  const handleSubmit1 = () => {
    console.log("Form Data:", formData);
    handleCloseModal1();
  };




  return (
    <>
      <Box sx={{ p: 1 }}>
        <Typography variant="h6" align="center" gutterBottom sx={{ fontWeight: "bold" }}>
          Customer Information
        </Typography>

        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              name="firstName"
              label="First Name"
              variant="outlined"
              value={formData.firstName}
              onChange={handleChange}
              error={!!errors.firstName}
              helperText={errors.firstName}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Person />
                  </InputAdornment>
                ),
                style: { height: '45px' },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Last Name"
              name="lastName"
              variant="outlined"
              value={formData.lastName}
              onChange={handleChange}
              error={!!errors.lastName}
              helperText={errors.lastName}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Person />
                  </InputAdornment>
                ),
                style: { height: '45px' },

              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="address"
              label="Address"
              variant="outlined"
              value={formData.address}
              onChange={handleChange}
              error={!!errors.address}
              helperText={errors.address}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Home />
                  </InputAdornment>
                ),
                style: { height: '45px' },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              name="city"
              label="City"
              variant="outlined"
              value={formData.city}
              onChange={handleChange}
              error={!!errors.city}
              helperText={errors.city}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LocationCity />
                  </InputAdornment>
                ),
                style: { height: '45px' },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              name="state"
              label="State"
              variant="outlined"
              value={formData.state}
              onChange={handleChange}
              error={!!errors.state}
              helperText={errors.state}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MapIcon />
                  </InputAdornment>
                ),
                style: { height: '45px' },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              name="zipCode"
              label="Zip Code"
              variant="outlined"
              value={formData.zipCode}
              onChange={handleChange}
              error={!!errors.zipCode}
              helperText={errors.zipCode}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CodeIcon />
                  </InputAdornment>
                ),
                style: { height: '45px' },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              name="yearBuilt"
              label="Year Built"
              variant="outlined"
              value={formData.yearBuilt}
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <HomeIcon />
                  </InputAdornment>
                ),
                style: { height: '45px' },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Phone"
              name="phone"
              variant="outlined"
              value={formData.phone}
              onChange={handleChange}
              error={!!errors.phone}
              helperText={errors.phone}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Phone />
                  </InputAdornment>
                ),
                style: { height: '45px' },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              name="cell"
              label="Cell"
              variant="outlined"
              value={formData.cell}
              onChange={handleChange}
              error={!!errors.cell}
              helperText={errors.cell}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CellTowerIcon />
                  </InputAdornment>
                ),
                style: { height: '45px' },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="work"
              label="Work"
              variant="outlined"
              value={formData.work}
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Work />
                  </InputAdornment>
                ),
                style: { height: '45px' },
              }}
            />
          </Grid>
        </Grid>

        <Box sx={{ mt: 4, display: "flex", justifyContent: "center", gap: 1 }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#5F1C1D",
              borderRadius: 2,
              color: "white",
              "&:hover": {
                backgroundColor: "#FDF7EF",
                color: "black",
              },
            }}
            onClick={handleOpenModal1}
          >
            Door Configration Detailes
          </Button>
        </Box>
      </Box>




      <Dialog
        open={modalOpen1}
        onClose={handleCloseModal1}
        maxWidth="md"
        fullWidth
      >

        <DialogContent sx={{ maxHeight: "80vh" }}>
          <TableContainer component={Paper}>
            <h2 style={{ textAlign: 'center', margin: '20px 5px', fontWeight: 'bold' }}>Door Configuration</h2>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Category</TableCell>
                  <TableCell align="right">Detail Type</TableCell>
                  <TableCell align="right">Width (Inches)&nbsp;</TableCell>
                  <TableCell align="right">Height (Inches)&nbsp;</TableCell>
                  <TableCell align="right">Sqft/Lft&nbsp;</TableCell>
                  <TableCell align="right">Unit Cost&nbsp;</TableCell>
                  <TableCell align="right">Estimated Cost&nbsp;</TableCell>
                  <TableCell align="right">Price&nbsp;</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (


                  <TableRow
                    key={row.CATEGEORY}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.CATEGEORY}
                    </TableCell>
                    <TableCell align="right">{row.SELCTIONS}</TableCell>
                    <TableCell align="right">{row.WIDTH}</TableCell>
                    <TableCell align="right">{row.HEIGHT}</TableCell>
                    <TableCell align="right">{row.SQFTLFT}</TableCell>
                    <TableCell align="right">{row.UNITCOST}</TableCell>
                    <TableCell align="right">{row.ESTIMATEDCOST}</TableCell>
                    <TableCell align="right">{row.PRICE}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal1} color="primary">
            Cancel
          </Button>
          <Box
            onClick={handleSubmit1}
            sx={{
              backgroundColor: "#5F1C1D",
              color: "#fff",
              borderRadius: 1,
              padding: "6px 16px",
              cursor: "pointer",
            }}
          >
            <Typography>Submit</Typography>
          </Box>
        </DialogActions>
      </Dialog>


    </>
  );
}
