import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio'; 
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import AsyncStorage from '@react-native-async-storage/async-storage'; // For React Native 

const Istallations = ({ onSelect }: { onSelect: (component: React.ReactNode) => void }) => {
  const [installationsunitcost, setInstallationsunitcost] = React.useState<number>(550);
  const [installationsunitcost2, setInstallationsunitcost2] = React.useState<number>(300);
  const [trimexterior2UnitCost2, setTrimexterior2UnitCost2] = React.useState<number>( );
  const [showInput, setShowInput] = React.useState<boolean>(false);
  const [showInput1, setShowInput1] = React.useState<boolean>(false);
  const [showInput2, setShowInput2] = React.useState<boolean>(false);
  const [installationOutOfTown, setInstallationOutOfTown] = React.useState<string>('no');
  const [freightIn, setFreightIn] = React.useState<string>('no');

  const KIT_COLOR = '#5F1C1D';
  const CustomRadio = styled(Radio)({
    color: KIT_COLOR,
    '&.Mui-checked': {
      color: KIT_COLOR,
    },
  });

  const handleTrimexterior2UnitCostChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(event.target.value) || 0;
    setInstallationsunitcost(value);
    await AsyncStorage.setItem('installationsunitcost', value.toString());
  };

  const handleTrimexterior2UnitCostChange1 = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(event.target.value) || 0;
    setInstallationsunitcost2(value);
    await AsyncStorage.setItem('installationsunitcost2', value.toString());
  };

  const handleTrimexterior2UnitCostChange2 = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(event.target.value) || 0;
    setTrimexterior2UnitCost2(value);
    await AsyncStorage.setItem('trimexterior2UnitCost2', value.toString());
  };

  const handleRadioChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value;
    setInstallationOutOfTown(selectedValue);
    setShowInput(selectedValue === 'yes');
    await AsyncStorage.setItem('installationOutOfTown', selectedValue);
  };

  const handleRadioChange1 = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value;
    setFreightIn(selectedValue);
    setShowInput1(selectedValue === 'yes');
    await AsyncStorage.setItem('freightIn', selectedValue);
  };

  React.useEffect(() => {
    const loadValues = async () => {
      const storedTrimexterior2UnitCost = await AsyncStorage.getItem('installationsunitcost');
      const storedTrimexterior2UnitCost1 = await AsyncStorage.getItem('installationsunitcost2');
      const storedTrimexterior2UnitCost2 = await AsyncStorage.getItem('trimexterior2UnitCost2');
      const storedInstallationOutOfTown = await AsyncStorage.getItem('installationOutOfTown');
      const storedFreightIn = await AsyncStorage.getItem('freightIn');

      if (storedTrimexterior2UnitCost) setInstallationsunitcost(parseFloat(storedTrimexterior2UnitCost));
      if (storedTrimexterior2UnitCost1) setInstallationsunitcost2(parseFloat(storedTrimexterior2UnitCost1));
      if (storedTrimexterior2UnitCost2) setTrimexterior2UnitCost2(parseFloat(storedTrimexterior2UnitCost2));
      if (storedInstallationOutOfTown) {
        setInstallationOutOfTown(storedInstallationOutOfTown);
        setShowInput(storedInstallationOutOfTown === 'yes');
      }
      if (storedFreightIn) {
        setFreightIn(storedFreightIn);
        setShowInput1(storedFreightIn === 'yes');
      }
    };

    loadValues();
  }, []);

  return (
    <Box sx={{ p: 1 }}>
      <Typography variant="h6" align="center" gutterBottom sx={{ fontWeight: 'bold' }}>
        Installation
      </Typography>
      <Typography
        variant="h6"
        sx={{ backgroundColor: '#5F1C1D', color: 'white', pl: 2, pt: 0.5, pb: 0.5, mt: 2,mb:2 }}
      >
        Installation out of town
      </Typography>

      <FormControl sx={{ pl: 2, mt: 2 }}>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={installationOutOfTown}
          onChange={handleRadioChange}
        >
          <FormControlLabel value="yes" control={<CustomRadio />} label="Yes" />
          <FormControlLabel value="no" control={<CustomRadio />} label="No" />
        </RadioGroup>
      </FormControl>
      <br />
      
      {showInput && (
        <TextField
          label="Unit Cost"
          value={installationsunitcost}
          onChange={handleTrimexterior2UnitCostChange}
          variant="outlined"
          type="number"  InputProps={{ inputProps: { min: 0 } }}
          inputProps={{ step: '1' }}
          style={{ width: '100%' }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: KIT_COLOR, // Default border color
              },
              '&:hover fieldset': {
                borderColor: KIT_COLOR, // Border color on hover
              },
              '&.Mui-focused fieldset': {
                borderColor: KIT_COLOR, // Border color when focused
              },
            },
            '& .MuiInputLabel-root': {
              color: KIT_COLOR, // Label color
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: KIT_COLOR, // Label color when focused
            },
          }}
        />
      )}

      <Typography
        variant="h6"
        sx={{ backgroundColor: '#5F1C1D', color: 'white', pl: 2, pt: 0.5, pb: 0.5, mt: 2,mb:2 }}
      >
        Freight IN
      </Typography>

      <FormControl sx={{ pl: 2, mt: 2 }}>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={freightIn}
          onChange={handleRadioChange1}
        >
          <FormControlLabel value="yes" control={<CustomRadio />} label="Yes" />
          <FormControlLabel value="no" control={<CustomRadio />} label="No" />
        </RadioGroup>
      </FormControl>
      <br />
      
      {showInput1 && (
        <TextField
          label="Unit Cost"
          value={installationsunitcost2}
          onChange={handleTrimexterior2UnitCostChange1}
          variant="outlined"
          type="number" InputProps={{ inputProps: { min: 0 } }}
          inputProps={{ step: '1' }}
          style={{ width: '100%' }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: KIT_COLOR, // Default border color
              },
              '&:hover fieldset': {
                borderColor: KIT_COLOR, // Border color on hover
              },
              '&.Mui-focused fieldset': {
                borderColor: KIT_COLOR, // Border color when focused
              },
            },
            '& .MuiInputLabel-root': {
              color: KIT_COLOR, // Label color
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: KIT_COLOR, // Label color when focused
            },
          }}
        />
      )}

      {showInput2 && (
        <TextField
          label="Unit Cost"
          value={trimexterior2UnitCost2}
          onChange={handleTrimexterior2UnitCostChange2}
          variant="outlined"
          type="number"  InputProps={{ inputProps: { min: 0 } }}
          inputProps={{ step: '1' }}
          style={{ width: '100%' }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: KIT_COLOR, // Default border color
              },
              '&:hover fieldset': {
                borderColor: KIT_COLOR, // Border color on hover
              },
              '&.Mui-focused fieldset': {
                borderColor: KIT_COLOR, // Border color when focused
              },
            },
            '& .MuiInputLabel-root': {
              color: KIT_COLOR, // Label color
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: KIT_COLOR, // Label color when focused
            },
          }}
        />
      )}

      <br />
      <br />
      
    </Box>
  );
};

export default Istallations;
