// ColorContext.tsx
import React, { createContext, useContext, useState, ReactNode } from "react";

interface ColorContextType {
  selectedColor: string;
  setSelectedColor: (color: string) => void;
  selectedGet: string;
  setSelectedGet: (color: string) => void;
  selectedDoor: string;
  setSelectedDoor: (color: string) => void;
  selectedGlass: string;
  setSelectedGlass: (color: string) => void;

  selectedArchIndex: string;
  setSelectedArchIndex: (color: string) => void;

  selectedColorGetIndex: string;
  setSelectedColorGetIndex: (color: string) => void;

  selectedHandleIndex: string;
  setSelectedHandleIndex: (color: string) => void;
  selectedGrillIndex: string;
  setSelectedGrillIndex: (color: string) => void;

  selectedbackfront: string;
  setSelectedbackfront: (color: string) => void;

  selectedbackcolor: string;
  setSelectedbackcolor: (color: string) => void;

  selectedArchDoorIndex: string;
  setSelectedArchDoorIndex: (color: string) => void;

  typeOfDoor: string;
  setTypeOfDoor: (color: string) => void;

  sideLightGrill: string;
  setSideLightGrill: (color: string) => void;


}

const ColorContext = createContext<ColorContextType | undefined>(undefined);

export const ColorProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [selectedColor, setSelectedColor] = useState<string>("");
  const [selectedGet, setSelectedGet] = useState<string>("");
  const [selectedDoor, setSelectedDoor] = useState<string>("");
  const [selectedGlass, setSelectedGlass] = useState<string>("");
  const [selectedArchIndex, setSelectedArchIndex] = useState<string>("");
  const [selectedHandleIndex, setSelectedHandleIndex] = useState<string>("");
  const [selectedGrillIndex, setSelectedGrillIndex] = useState<string>("");

  const [selectedColorGetIndex,setSelectedColorGetIndex] = useState<string>("");
  const [selectedbackfront,setSelectedbackfront] = useState<string>("");
  const [selectedbackcolor,setSelectedbackcolor] = useState<string>("");
  const [selectedArchDoorIndex, setSelectedArchDoorIndex] = useState<string>("");
  const [typeOfDoor, setTypeOfDoor] = useState<string>("");
  const [sideLightGrill , setSideLightGrill ] = useState<string>("");


  return (
    <ColorContext.Provider
      value={{ selectedColor, setSelectedColor, selectedGet, setSelectedGet,selectedDoor,setSelectedDoor,selectedGlass,
        setSelectedGlass,setSelectedArchIndex,selectedArchIndex,selectedColorGetIndex,setSelectedColorGetIndex,
         selectedGrillIndex, setSelectedGrillIndex,selectedHandleIndex, setSelectedHandleIndex,selectedbackfront,
         setSelectedbackfront,selectedbackcolor,setSelectedbackcolor,selectedArchDoorIndex, setSelectedArchDoorIndex,
         typeOfDoor, setTypeOfDoor, sideLightGrill , setSideLightGrill}}
    >
      {children}
    </ColorContext.Provider>
  );
};

export const useColorContext = () => {
  const context = useContext(ColorContext);
  if (!context) {
    throw new Error("useColorContext must be used within a ColorProvider");
  }
  return context;
};
 