import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { CheckCircle } from "@mui/icons-material";
import { useColorContext } from "../ColorContext";
import "../Door/Doorcss.css";
import "../Door/Doubledoor.css";
import { styled } from "@mui/material/styles";
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Slider,
  TextField,
  MenuItem,
  Select
} from "@mui/material";
import { Color } from "three";
import { log } from "console";

interface DoorcomProps {
  onSelect: (value: any) => void; // Change 'any' to the appropriate type if known
}

const DoorType = ({
  onSelect,
}: {
  onSelect: (component: React.ReactNode) => void;
}) => {

  const KIT_COLOR = "#5F1C1D";
  const CustomRadio = styled(Radio)({
    color: KIT_COLOR,
    "&.Mui-checked": {
      color: KIT_COLOR,
    },
  });

  const [selectedSprite, setSelectedSprite] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { setSelectedDoor } = useColorContext() as any;
  const [showInput, setShowInput] = useState<string>("singledoor");
  const [width, setWidth] = useState<any>(() => {
    return Number(localStorage.getItem("width")) || '';
  });
  const [height, setHeight] = useState<any>(() => {
    return Number(localStorage.getItem("height")) || '';
  });
  const [unitCost, setUnitCost] = useState<any>(() => {
    return Number(localStorage.getItem("unitCost")) || 60;
  });
  const [unitCost1, setUnitCost1] = useState<any>(() => {
    return Number(localStorage.getItem("unitCost1")) || '';
  });

  const [TransformandSlidelight, setTransformandSlidelight] = useState<string>(() => {
    return String(localStorage.getItem("TransformandSlidelight")) || '';
  });
  const [doorSwing, setDoorSwing] = useState<string>(() => {
    return String(localStorage.getItem("doorSwing")) || '';
  });
  const [hardwareType, setHardwareType] = useState<string>(() => {
    return (localStorage.getItem("hardwareType")) || '';
  });

  console.log("ayush=======>",hardwareType)

  // const [hardwareType, setHardwareType] = useState<string>("");
  // const [doorSwing, setDoorSwing] = useState<string>("");
  // const [TransformandSlidelight,setTransformandSlidelight] = useState<string>("");




  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    localStorage.setItem("width", width.toString());
  }, [width]);

  useEffect(() => {
    localStorage.setItem("height", height.toString());
  }, [height]);

  useEffect(() => {
    localStorage.setItem("unitCost", unitCost.toString());
  }, [unitCost]);

  useEffect(() => {
    localStorage.setItem("unitCost1", unitCost1.toString());
  }, [unitCost1]);

  useEffect(() => {    
    localStorage.setItem("TransformandSlidelight", TransformandSlidelight.toString());
  }, [TransformandSlidelight]);

  useEffect(() => {    
    localStorage.setItem("hardwareType", hardwareType.toString());
  }, [hardwareType]);

  useEffect(() => {
    console.log('------------->>>>>>>>>>>>>>>>>>>>>>>>>>>>>>',doorSwing)
    
    localStorage.setItem("doorSwing", doorSwing.toString());
  }, [doorSwing]);
  
  const handleSpriteClick = (gate: string) => {
    setSelectedSprite(gate);
    setSelectedDoor(gate);
  };
  const handleDoorSwingChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setDoorSwing(event.target.value as string);
  };


  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowInput(event.target.value);
  };

  const handleWidthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWidth(Number(event.target.value));
  };

  const handleHeightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHeight(Number(event.target.value));
  };

  const handleUnitCostChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUnitCost(Number(event.target.value));
  };
  const handleUnitCostChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUnitCost1(Number(event.target.value));
  };
  const handleWidthSliderChange = (
    event: Event,
    newValue: number | number[]
  ) => {
    setWidth(newValue as number);
  };

  const handleHeightSliderChange = (
    event: Event,
    newValue: number | number[]
  ) => {
    setHeight(newValue as number);
  };

  // const [doorsqrfeet,setDoorsqrfeet]=useState()

  const doorsqrfeet = (width * height) / 144;
  const Area=doorsqrfeet ;
  const UnitCost=unitCost 
  const Estimatedcost=Area*UnitCost;
  console.log("Estimatedcost",Estimatedcost)
  const Price = Estimatedcost * 2.5
  const EstimatedCost1=Estimatedcost*0.2
  console.log('-------------------------->>>>>>>>>>>>>>>',EstimatedCost1);
  useEffect(() => {    
    localStorage.setItem("Estimatedcost", EstimatedCost1.toString());
  }, [EstimatedCost1]);
  
  return (
    <Box>

<Typography  variant="h6" align="center" gutterBottom sx={{  backgroundColor: "#5F1C1D",color:'white' }}>
        Door Hardware & Size
      </Typography>

      <FormControl variant="outlined" fullWidth sx={{ mb: 2 }}>
        <Select
          value={hardwareType}
          onChange={(e) => setHardwareType(e.target.value)}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem value=""><em>Select Hardware Type</em></MenuItem>
          <MenuItem value="low-profile">Low Profile</MenuItem>
          <MenuItem value="hand-forget">Hand Forget</MenuItem>
          <MenuItem value="sliding">Sliding</MenuItem>
          <MenuItem value="folding">Folding</MenuItem>
        </Select>
      </FormControl>

     
      <hr />

    {hardwareType !=  '' ?   <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <Typography>Width</Typography>
        <input
          type="number"
          // min=""
          value={width}
          onChange={handleWidthChange}
          style={{
            textAlign: "center",
            width: "80px",
            marginRight: "8px",
            fontWeight: "bold",
            border: "1px solid #ccc",
            borderRadius: "4px",
            height: "35px",
            fontSize: "16px",
          }}
        />
        <Slider
          aria-label="Width"
          value={width}
          onChange={handleWidthSliderChange}
          step={1}
          min={0}
          max={1110}
          sx={{ color: KIT_COLOR, width: "300px", marginLeft: "8px" }}
        />

        <Typography>Height</Typography>
        <input
          type="number"
          min="0"
          value={height}
          onChange={handleHeightChange}
          style={{
            textAlign: "center",
            width: "80px",
            marginRight: "8px",
            fontWeight: "bold",
            border: "1px solid #ccc",
            borderRadius: "4px",
            height: "35px",
            fontSize: "16px",
          }}
        />
        <Slider
          aria-label="Height"
          value={height}
          onChange={handleHeightSliderChange}
          step={1}
          min={0}
          max={1110}
          sx={{ color: KIT_COLOR, width: "300px", marginLeft: "8px" }}
        />

        <Box mt={2} display="flex" alignItems="center">
          <TextField
            label="Sqr/ft"
            value={doorsqrfeet.toFixed(2)}
            InputProps={{ readOnly: true }}
            variant="outlined"
            style={{ marginRight: 16 }}
            InputLabelProps={{ style: { color: KIT_COLOR } }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "defaultBorderColor", // Default border color
                },
                "&:hover fieldset": {
                  borderColor: "defaultBorderColor", // Border color on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: KIT_COLOR, // Border color when focused
                },
              },
            }}
          />
          <TextField
            label="Unit Cost"
            variant="outlined"
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
            value={unitCost}
            onChange={handleUnitCostChange}
            inputProps={{ step: "1" }}
            style={{ width: "100%" }}
            InputLabelProps={{ style: { color: KIT_COLOR } }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "defaultBorderColor", // Default border color
                },
                "&:hover fieldset": {
                  borderColor: "defaultBorderColor", // Border color on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: KIT_COLOR, // Border color when focused
                },
              },
            }}
          />
        </Box>

       
      </Box>
 : ''}





{/* <Box>
<Typography variant="h6" align="center" gutterBottom sx={{ backgroundColor: "#5F1C1D", color: 'white',marginTop: '20px' }}>
        Door Swing From Ext View
      </Typography>
      <FormControl variant="outlined" fullWidth sx={{ mb: 2 }}>
        <Select
          value={doorSwing}
          onChange={(e) => setDoorSwing(e.target.value)}
          // onChange={handleDoorSwingChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem value=""><em>Select Swing Direction</em></MenuItem>
          <MenuItem value="TBD">TBD</MenuItem>
          <MenuItem value="IN RIGHT">In Right</MenuItem>
          <MenuItem value="IN LEFT">In Left</MenuItem>
          <MenuItem value="OUR RIGHT">Our Right</MenuItem>
          <MenuItem value="OUR LEFT">Our Left</MenuItem>
          <MenuItem value="SLIDE">Slide</MenuItem>
          <MenuItem value="FOLD">Fold</MenuItem>
        </Select>
      </FormControl>
</Box> */}

      {/* Show unit cost text field if a swing option is selected */}
      


      {/* {doorSwing && (
        <TextField
          label="Unit Cost"
          variant="outlined"
          type="number"
          InputProps={{ inputProps: { min: 0 } }}
          value={unitCost1}
          onChange={handleUnitCostChange1}
          inputProps={{ step: "1" }}
          style={{ width: "100%", marginTop: '10px' }}
          InputLabelProps={{ style: { color: KIT_COLOR } }}
        />
      )} */}


<Box>
<Typography variant="h6" align="center" gutterBottom sx={{ backgroundColor: "#5F1C1D", color: 'white' ,marginTop: '20px'}}>
Door Transform and Slidelight
      </Typography>
      <FormControl variant="outlined" fullWidth sx={{ mb: 2 }}>
        <Select
          value={TransformandSlidelight}
          onChange={(e) => setTransformandSlidelight(e.target.value)}
          // onChange={handleDoorSwingChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem value=""><em>Select Transform and Slidelight</em></MenuItem>
          <MenuItem value="W/SIDELIGHTS">W/SIDELIGHTS</MenuItem>
          <MenuItem value="W/TRANSOM">W/TRANSOM</MenuItem>
          <MenuItem value="W/SIDELIGHTS & TRANSOM">W/SIDELIGHTS & TRANSOM</MenuItem>
          <MenuItem value="W/OPERABLE GLASS">W/OPERABLE GLASS</MenuItem>
          <MenuItem value="W/SIMULATED DIVIDED LIGHTS">W/SIMULATED DIVIDED LIGHTS</MenuItem>
          <MenuItem value="W/TRUE DIVIDED LIGHTS">W/TRUE DIVIDED LIGHTS</MenuItem>
      
        </Select>
      </FormControl>
</Box>








      {/* <Typography
      
        variant="h6"
        align="center"
        gutterBottom
        sx={{ backgroundColor: "#5F1C1D",color:'white', marginTop: '20px'}}
      >
        Door
      </Typography>
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={handleRadioChange}
          value={showInput}
        >
          <FormControlLabel
            value="singledoor"
            control={<CustomRadio />}
            label="Single Door"
          />
          <FormControlLabel
            value="doubledoor"
            control={<CustomRadio />}
            label="Double Door"
          />
        </RadioGroup>
      </FormControl> */}

      <br />

      {/* {showInput === "singledoor" ? (
        <>
          {isLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100px",
                marginTop: "200px",
              }}
            >
              <CircularProgress color="success" />
            </Box>
          ) : (
            <Grid container spacing={2} className="sprite-container">
              {[
                "with-sidelight-and-transom",
                "with-sidelights",
                "with-simulated-divided-light",
                "with-transom",
                "with-true-divide-lights",
                "with-operable-glass",
              ].map((type, index) => (
                <Grid item xs={6} sm={6} md={6} key={type}>
                  <span
                    className={`css-sprite-${type}`}
                    onClick={() => handleSpriteClick(type)}
                    style={{
                      position: "relative",
                      cursor: "pointer",
                      display: "block",
                    }}
                  >
                    {selectedSprite === type && (
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          width: "36px",
                          height: "36px",
                          borderRadius: "50%",
                          backgroundColor: "rgba(255, 255, 255, 0.7)",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          boxShadow: "0 0 0 2px rgba(0, 0, 0, 0.2)",
                          zIndex: 1,
                        }}
                      >
                        <CheckCircle
                          style={{ color: KIT_COLOR, fontSize: "24px" }}
                        />
                      </div>
                    )}
                  </span>
                </Grid>
              ))}
            </Grid>
          )}
          <br />
        </>
      ) : (
        <>
          {isLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100px",
                marginTop: "200px",
              }}
            >
              <CircularProgress color="success" />
            </Box>
          ) : (
            <Grid container spacing={2} className="sprite-container">
              {[
                "double-door-true-divide-lights",
                "double-door-sidelights1",
                "double-door-sidelights",
                "double-door-transom",
                "double-door-sidelight-and-transom",
                "double-door-simulated-divided-light",
              ].map((type, index) => (
                <Grid item xs={6} sm={6} md={6} key={type}>
                  <span
                    className={`css-sprite-${type}`}
                    onClick={() => handleSpriteClick(type)}
                    style={{
                      position: "relative",
                      cursor: "pointer",
                      display: "block",
                    }}
                  >
                    {selectedSprite === type && (
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          width: "36px",
                          height: "36px",
                          borderRadius: "50%",
                          backgroundColor: "rgba(255, 255, 255, 0.7)",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          boxShadow: "0 0 0 2px rgba(0, 0, 0, 0.2)",
                          zIndex: 1,
                        }}
                      >
                        <CheckCircle
                          style={{ color: KIT_COLOR, fontSize: "24px" }}
                        />
                      </div>
                    )}
                  </span>
                </Grid>
              ))}
            </Grid>
          )}
        </>
      )} */}
    </Box>
  );
};

export default DoorType;
