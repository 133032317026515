import * as React from "react";

import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";

import "./Glasscss.css";
import "./Glass2.css";
import { useColorContext } from "../ColorContext";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import AsyncStorage from "@react-native-async-storage/async-storage";

import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useState } from "react";

// import Thicknessofdoor from './Thicknessofdoor';
// import Jambdepth from './Jambdepth';
// import Jambwidth from './Jambwidth';

const KIT_COLOR = "#5F1C1D";
const CustomRadio = styled(Radio)({
  color: KIT_COLOR,
  "&.Mui-checked": {
    color: KIT_COLOR,
  },
});

const widthOptions = ["TBD", "LOWE1", "LOWE2", "LOWE3", "FLEMISH", "CUSTOM"];
const depthOptions = [1 / 2, 1 / 4, 1 / 8, 3 / 4, 3 / 8, 3 / 16, 5 / 8, 9 / 16];

const Glasscom = ({
  onSelect,
}: {
  onSelect: (component: React.ReactNode) => void;
}) => {
  // const KIT_COLOR:any = "#5F1C1D";
  const [selectedSprite, setSelectedSprite] = React.useState<string | null>(
    null
  );
  const [width, setWidth] = useState<number | string>(widthOptions[0]);
  const [glassDepth, setGlassDepth] = useState<number | string>(depthOptions[0]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoading1, setIsLoading1] = React.useState(false);
  const { setSelectedGlass } = useColorContext(); // Get the selected gate from context 
   const [depth, setDepth] = useState<string | number>("1/2");
 





  const handleSpriteClick = (gate: string) => {
    setSelectedSprite(gate);
    setSelectedGlass(gate);
  };

  const [firstValue, setFirstValue] = React.useState<number |"">("");

  // Handle change in the first input field
  const handleFirstChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    // Parse the value to a number and set it to state
    setFirstValue(value ? Number(value) : "");
  };

  const [firstValue1, setFirstValue1] = React.useState<number | "">("");

  // Handle change in the first input field
  const handleFirstChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    // Parse the value to a number and set it to state
    setFirstValue1(value ? Number(value) : "");
  };

  const [fittingUnitCost, setFittingUnitCost] = React.useState<number>(30);
  const [fittingUnitCost1, setFittingUnitCost1] = React.useState<number>(30);
  const [fittingUnitCost2, setFittingUnitCost2] = React.useState<number>(30);
  const [fittingUnitCost3, setFittingUnitCost3] = React.useState<number>(30);

  const [showfittingInput, setShowfittingInput] =
    React.useState<boolean>(false);
  const [showfittingInput1, setShowfittingInput1] =
    React.useState<boolean>(false);
  const [showfittingInput2, setShowfittingInput2] =
    React.useState<boolean>(false);
  const [showfittingInput3, setShowfittingInput3] =
    React.useState<boolean>(false);

  // Handler for showing/hiding input based on radio button selection
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value;
    setShowfittingInput(selectedValue === "yes");
  };

  const handleWidthChange = (event: SelectChangeEvent<string | number>) => {
    setWidth(event.target.value as number | string);
  };

 

  // Handler for showing/hiding input based on radio button selection
  const handleRadioChange3 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value;
    setShowfittingInput3(selectedValue === "yes");
  };

  const handleTrimexterior2UnitCostChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = parseFloat(event.target.value) || 0;
    setFittingUnitCost(value);
    // await AsyncStorage.setItem('fittingUnitCost', value.toString());
  };

  const handleTrimexterior2UnitCostChange1 = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = parseFloat(event.target.value) || 0;
    setFittingUnitCost1(value);
    // await AsyncStorage.setItem('fittingUnitCost1', value.toString());
  };

  const handleTrimexterior2UnitCostChange2 = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = parseFloat(event.target.value) || 0;
    setFittingUnitCost2(value);
    // await AsyncStorage.setItem('fittingUnitCost2', value.toString());
  };

  const handleTrimexterior2UnitCostChange3 = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = parseFloat(event.target.value) || 0;
    setFittingUnitCost3(value);
    // await AsyncStorage.setItem('fittingUnitCost3', value.toString());
  };

  React.useEffect(() => {
    if (showfittingInput1) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 800);
    }
  }, [showfittingInput1]);

  React.useEffect(() => {
    if (showfittingInput2) {
      setIsLoading1(true);
      setTimeout(() => {
        setIsLoading1(false);
      }, 800);
    }
  }, [showfittingInput2]);

  

  const handleDepthChange = async (event: SelectChangeEvent<string | number>) => {
    const newDepth = event.target.value as number | string;
    setGlassDepth(newDepth);
    await AsyncStorage.setItem("glassDepth", newDepth.toString());
  };

  // Effect to retrieve glassDepth value from AsyncStorage
  React.useEffect(() => {
    const fetchDepthValue = async () => {
      const storedDepth = await AsyncStorage.getItem("glassDepth");
      if (storedDepth) {
        setGlassDepth(storedDepth);
      }
    };

    fetchDepthValue();
  }, []);








  const handleRadioChange1 = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value;
    setShowfittingInput1(selectedValue === "yes");
    await AsyncStorage.setItem("operableOrFixed", selectedValue); // Store value
  };

  const handleRadioChange2 = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value;
    setShowfittingInput2(selectedValue === "yes");
    await AsyncStorage.setItem("glassSelections", selectedValue); // Store value
  };

  React.useEffect(() => {
    const fetchRadioValues = async () => {
      const operableOrFixedValue = await AsyncStorage.getItem("operableOrFixed");
      if (operableOrFixedValue) {
        setShowfittingInput1(operableOrFixedValue === "yes");
      }

      const glassSelectionsValue = await AsyncStorage.getItem("glassSelections");
      if (glassSelectionsValue) {
        setShowfittingInput2(glassSelectionsValue === "yes");
      }
    };

    fetchRadioValues();
  }, []);










  return (
    <Box>
    <Typography variant="h6" align="center" gutterBottom sx={{ fontWeight: "bold" }}>Glass</Typography>

    <Typography
        variant="h6"
        sx={{ backgroundColor: '#5F1C1D', color: 'white', pl: 2, pt: 0.5, pb: 0.5, mt: 2,mb:2 }}
      >      Operable or Fixed
    </Typography>

    <FormControl sx={{ pl: 2, mt: 2 }}>
      <RadioGroup
        row
        name="operableOrFixed"
        onChange={handleRadioChange1}
        defaultValue={showfittingInput1 ? "yes" : "no"}
      >
        <FormControlLabel value="yes" control={<CustomRadio />} label="Yes" />
        <FormControlLabel value="no" control={<CustomRadio />} label="No" />
      </RadioGroup>
    </FormControl>
    <br />

    {showfittingInput1 && (
        <>
          {isLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100px",
                marginTop: "200px",
              }}
            >
              <CircularProgress color="success" />
            </Box>
          ) : (
            <Grid container spacing={2} className="sprite-container">
              {[
                "insulated-glass",
                "Laminated-Glass",
                "tempered-glass",
                "annealed-glass",
                "flat-glass",
                "flat-glass2",
              ].map((type) => (
                <Grid item xs={6} sm={6} md={6} key={type}>
                  <span
                    className={`css-sprite-${type}`}
                    onClick={() => handleSpriteClick(type)}
                    style={{
                      position: "relative",
                      cursor: "pointer",
                      display: "block",
                    }}
                  >
                    {selectedSprite === type && (
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          width: "36px",
                          height: "36px",
                          borderRadius: "50%",
                          backgroundColor: "rgba(255, 255, 255, 0.7)", // Semi-transparent background
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          boxShadow: "0 0 0 2px rgba(0, 0, 0, 0.2)", // Optional shadow for better visibility
                          zIndex: 1, // Ensure it appears above the sprite
                        }}
                      >
                        <CheckCircle
                          style={{ color: "#5F1C1D", fontSize: "24px" }}
                        />
                      </div>
                    )}
                  </span>
                </Grid>
              ))}
            </Grid>
          )}
        </>
      )}

      <Typography
        variant="h6"
        sx={{ backgroundColor: '#5F1C1D', color: 'white', pl: 2, pt: 0.5, pb: 0.5, mt: 2,mb:2 }}>       
       Glass Selections
      </Typography>

      <FormControl sx={{ pl: 2, mt: 2 }}>
        <RadioGroup
          row
          name="glassSelections"
          onChange={handleRadioChange2}
          defaultValue={showfittingInput2 ? "yes" : "no"}
        >
          <FormControlLabel value="yes" control={<CustomRadio />} label="Yes" />
          <FormControlLabel value="no" control={<CustomRadio />} label="No" />
        </RadioGroup>
      </FormControl>
      <br />
      
      {showfittingInput2 && (
        <>
        <Typography>Glass Thickness</Typography>
        <Select
        value={glassDepth}
        onChange={handleDepthChange}
        displayEmpty
        inputProps={{ "aria-label": "Select glassDepth" }}
        fullWidth
      >
        <MenuItem disabled value="">
          Over all Thickness
        </MenuItem>
        {depthOptions.map((option, index) => (
          <MenuItem key={index} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
          <br />
          <br />

          {isLoading1 ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100px",
                marginTop: "200px",
              }}
            >
              <CircularProgress color="success" />
            </Box>
          ) : (
            <Grid container spacing={2} className="sprite-container">
              {[
                "Rain",
                "reeded",
                "satin-etch",
                "solar-cool-bronze",
                "solar-cool-gray",
                "atlantic",
                "bronze",
                "clear-shgc-78",
                "clear-glass",
                "delta-clear",
                "flemish",
                "glacier",
                "glue-chip",
                "grey",
                "obscure",
              ].map((type) => (
                <Grid item xs={6} sm={6} md={6} key={type}>
                  <span
                    className={`css-sprite-${type}`}
                    onClick={() => handleSpriteClick(type)}
                    style={{
                      position: "relative",
                      cursor: "pointer",
                      display: "block",
                    }}
                  >
                    {selectedSprite === type && (
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          width: "36px",
                          height: "36px",
                          borderRadius: "50%",
                          backgroundColor: "rgba(255, 255, 255, 0.7)", // Semi-transparent background
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          boxShadow: "0 0 0 2px rgba(0, 0, 0, 0.2)", // Optional shadow for better visibility
                          zIndex: 1, // Ensure it appears above the sprite
                        }}
                      >
                        <CheckCircle
                          style={{ color: "#5F1C1D", fontSize: "24px" }}
                        />
                      </div>
                    )}
                  </span>
                </Grid>
              ))}
            </Grid>
          )}
        </>
      )}

      {/* <button onClick={() => onSelect(<GlassTemp />)}>GlassTemp</button> */}
    </Box>
  );
};

export default Glasscom;
