import { useEffect, useState } from "react";
import { Box, Grid, Typography, Switch, FormControlLabel } from "@mui/material";
import { useColorContext } from "../ColorContext";
import back from "../ColorImages/back.png";
import front from "../ColorImages/front.png";
import './color.css';

const colors = [
  '#61413D', '#3A2828', '#2E2525', '#5B3631', '#4B3838', 
  '#292525', '#646566', '#5D5F61', '#2C2D2E', '#727A82', 
  '#39393C', '#3D3F40', '#725A47', '#615347', '#302E2B', 
  '#695446', '#413934', '#3B393A', '#202227', '#20252A', 
  '#C7D3DD',
];

const colorName = [
  'AW-CO1', 'AW-CO2', 'AW-CO3', 'AW-CO4', 'AW-CO5', 
  'AW-CO6', 'AW-CO7', 'AW-CO8', 'AW-CO9', 'AW-CO10', 
  'AW-CO11', 'AW-CO12', 'AW-CO13', 'AW-CO14', 'AW-CO15', 
  'AW-CO16', 'AW-CO17', 'AW-CO18', 'AW-CO19', 'AW-CO20', 
  'AW-CO21',
];

// Define props interface
interface ColorcomProps {
  onSelect: (component: React.ReactNode) => void;
}

const Colorcom: React.FC<ColorcomProps> = ({ onSelect }) => {
  const { setSelectedColorGetIndex, setSelectedbackcolor } = useColorContext() as any;
  
  const [checked, setChecked] = useState(false);
  const [selectedColorName, setSelectedColorName] = useState<string | null>(null);

  const handleToggle = () => {
    setChecked((prev) => !prev);
  };

  const doorFront = (color: string, index: number) => {
    if (checked) {
      setSelectedbackcolor(color);
    }
    setSelectedColorGetIndex(color);
    setSelectedColorName(colorName[index]);


    localStorage.setItem("frontColorName",colorName[index]);


  };



  const doorBack = (color: string, index: number) => {
    if (!checked) {
      setSelectedbackcolor(color);
    }
    setSelectedColorName(colorName[index]);
  };

  const {  setSelectedbackfront,} = useColorContext();

 const images = [back, front];

  const backfront = (src: any, index: any) => {
    setSelectedbackfront(index);  
  
    console.log("back front==========>",index); // Convert to string
  };



  return (
    <>
      <Typography variant="h6" align="center" gutterBottom sx={{ fontWeight: 'bold' }}>
        Colors
      </Typography>

      <Typography variant="h6" sx={{ backgroundColor: "#5F1C1D", color: "white", pl: 2, pt: 0.5, pb: 0.5, mt: 2, mb: 2 }}>
        Both Side
      </Typography>

      <FormControlLabel
        control={
          <Switch
            checked={checked}
            onChange={handleToggle}
            name="toggle"
            color="primary"
          />
        }
        label={<Typography>{checked ? ' Choose single side of color' : 'Choose both side of color'}</Typography>}
      />

      <Typography variant="h6" sx={{ backgroundColor: '#5F1C1D', color: 'white', pl: 2, pt: 0.5, pb: 0.5, mt: 2, mb: 2 }}>
        Door Front Color
      </Typography>

      <div className="container">
        {colors.map((color, index) => (
          <div
            key={color}
            className="color-box"
            style={{ backgroundColor: color.toLowerCase() }}
            onClick={() => doorFront(color, index)}
          >
            {colorName[index]}
          </div>
        ))}
      </div>


      <Typography
        variant="h6"
        sx={{
          backgroundColor: "#5F1C1D",
          color: "white",
          pl: 2,
          pt: 0.5,
          pb: 0.5,
          mt: 2,
          mb: 2,
        }}
      >
        Front / Back 
      </Typography>

      <Grid container spacing={2}>
        {images.map((src, index) => (
          <Grid item xs={4} key={index}>
            <img
              src={src}
              alt={`Image ${index + 2}`}
              style={{ width: "50%", height: "auto", cursor: "pointer" }}
              onClick={() => backfront(src, index)}
            />
          </Grid>
        ))}

      </Grid>



      <Typography variant="h6" sx={{ backgroundColor: "#5F1C1D", color: "white", pl: 2, pt: 0.5, pb: 0.5, mt: 2, mb: 2 }}>
        Door Back Color
      </Typography>

      <div className="container">
        {colors.map((color, index) => (
          <div
            key={color}
            className="color-box"
            style={{ backgroundColor: color.toLowerCase() }}
            onClick={() => doorBack(color, index)}
          >
            {colorName[index]}
          </div>
        ))}
      </div>
{/* 
      {selectedColorName && (
        <Typography variant="h6" align="center" sx={{ mt: 2 }}>
          Selected Color: {selectedColorName}
        </Typography>
      )} */}
    </>
  );
};

export default Colorcom;
