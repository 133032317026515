import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

const Thresholdcom = ({
  onSelect,
}: {
  onSelect: (component: React.ReactNode) => void;
}) => {
  const [thresholdType, setThresholdType] = React.useState<string>(
    localStorage.getItem("thresholdType") || ""
  );
  const [thresholdHT, setThresholdHT] = React.useState<string>(
    localStorage.getItem("thresholdHT") || ""
  );
  const [customThresholdHT, setCustomThresholdHT] = React.useState<string>("");
  const [showCustomInput, setShowCustomInput] = React.useState<boolean>(false);
  const [thresholdunitCost, setThresholdunitCost] = React.useState<number>(
    parseFloat(localStorage.getItem("thresholdunitCost") || "170")
  );
  const [thresholdunitCost2, setUnitCost1] = React.useState<number>(
    parseFloat(localStorage.getItem("thresholdunitCost2") || "100")
  );
  const [thresholdwidth, setThresholdwidth] = React.useState<number | "">(
    parseFloat(localStorage.getItem("thresholdwidth") || "20")
  );
  const KIT_COLOR = "#5F1C1D";

  const handleThresholdTypeChange = (event: SelectChangeEvent<string>) => {
    const newValue = event.target.value as string;
    setThresholdType(newValue);
    localStorage.setItem("thresholdType", newValue);
  };

  const handleThresholdHTChange = (event: SelectChangeEvent<string>) => {
    const newValue = event.target.value as string;
    setThresholdHT(newValue);
    setShowCustomInput(newValue === "CUSTOM");
    if (newValue !== "CUSTOM") {
      setCustomThresholdHT(""); // Clear custom input if not selected
    }
    localStorage.setItem("thresholdHT", newValue);
  };

  const handleCustomThresholdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setCustomThresholdHT(newValue);
    setThresholdHT(newValue); // Update thresholdHT with custom input
    localStorage.setItem("thresholdHT", newValue);
  };

  const handleUnitCostChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(event.target.value) || 0;
    setThresholdunitCost(newValue);
    localStorage.setItem("thresholdunitCost", newValue.toString());
  };

  const handleUnitCostChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(event.target.value) || 0;
    setUnitCost1(newValue);
    localStorage.setItem("thresholdunitCost2", newValue.toString());
  };

  const handleWidthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(event.target.value) || "";
    setThresholdwidth(newValue);
    localStorage.setItem("thresholdwidth", newValue.toString());
  };

  return (
    <Box>
      <Typography
        variant="h6"
        align="center"
        gutterBottom
        sx={{ fontWeight: "bold" }}
      >
        Threshold
      </Typography>

      {/* Threshold Type Dropdown */}
      <Box sx={{ mb: 2 }}>
        <Typography
          variant="h6"
          sx={{
            backgroundColor: "#5F1C1D",
            color: "white",
            pl: 2,
            pt: 0.5,
            pb: 0.5,
            mt: 2,
            mb: 2,
          }}
        >
          Threshold Type
        </Typography>
        <Select
          value={thresholdType}
          onChange={handleThresholdTypeChange}
          displayEmpty
          fullWidth
        >
          <MenuItem value="">Select Threshold Type</MenuItem>
          <MenuItem value="STEP">STEP</MenuItem>
          <MenuItem value="EXTENDED STEPOUT SWING">
            EXTENDED STEPOUT SWING
          </MenuItem>
          <MenuItem value="ADA TOP OF FLOOR">ADA TOP OF FLOOR</MenuItem>
          <MenuItem value="ADDA - BUTT FLOOR">ADDA - BUTT FLOOR</MenuItem>
        </Select>
      </Box>
      {/* Show Unit Cost for Threshold Type Selection */}
      {thresholdType && (
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle1">Unit Cost</Typography>
          <TextField
            value={thresholdunitCost}
            onChange={handleUnitCostChange}
            fullWidth
            InputProps={{ inputProps: { min: 0 } }}
            type="number"
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: KIT_COLOR },
                "&:hover fieldset": { borderColor: KIT_COLOR },
                "&.Mui-focused fieldset": { borderColor: KIT_COLOR },
              },
              "& .MuiInputLabel-root": { color: KIT_COLOR },
              "& .MuiInputLabel-root.Mui-focused": { color: KIT_COLOR },
            }}
          />
        </Box>
      )}
      {/* Threshold HT Dropdown */}
      <Box sx={{ mb: 2 }}>
        <Typography
          variant="h6"
          sx={{
            backgroundColor: "#5F1C1D",
            color: "white",
            pl: 2,
            pt: 0.5,
            pb: 0.5,
            mt: 2,
            mb: 2,
          }}
        >
          Threshold HT
        </Typography>
        <Select
          value={thresholdHT}
          onChange={handleThresholdHTChange}
          displayEmpty
          fullWidth
        >
          <MenuItem value="">Select Threshold HT</MenuItem>
          <MenuItem value="3/4">3/4</MenuItem>
          <MenuItem value="1">1</MenuItem>
          <MenuItem value="1 1/2">1 1/2</MenuItem>
          <MenuItem value="2">2</MenuItem>
          <MenuItem value="2 1/2">2 1/2</MenuItem>
          <MenuItem value="CUSTOM">Custom</MenuItem>
        </Select>
      </Box>

      {/* Custom Threshold HT Input */}
      {showCustomInput && (
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle1">Custom Threshold HT</Typography>
          <TextField
            value={customThresholdHT}
            onChange={handleCustomThresholdChange}
            fullWidth
            placeholder="Enter custom value"
            type="text"
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: KIT_COLOR },
                "&:hover fieldset": { borderColor: KIT_COLOR },
                "&.Mui-focused fieldset": { borderColor: KIT_COLOR },
              },
              "& .MuiInputLabel-root": { color: KIT_COLOR },
              "& .MuiInputLabel-root.Mui-focused": { color: KIT_COLOR },
            }}
          />
        </Box>
      )}



      {/* Show thresholdwidth and Unit Cost fields */}
      {thresholdHT !== "" && (
        <>
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1">Threshold Width</Typography>
            <TextField
              value={thresholdwidth}
              onChange={handleWidthChange}
              fullWidth
              InputProps={{ inputProps: { min: 0 } }}
              type="number"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: KIT_COLOR },
                  "&:hover fieldset": { borderColor: KIT_COLOR },
                  "&.Mui-focused fieldset": { borderColor: KIT_COLOR },
                },
                "& .MuiInputLabel-root": { color: KIT_COLOR },
                "& .MuiInputLabel-root.Mui-focused": { color: KIT_COLOR },
              }}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1">Unit Cost</Typography>
            <TextField
              value={thresholdunitCost2}
              onChange={handleUnitCostChange1}
              fullWidth
              InputProps={{ inputProps: { min: 0 } }}
              type="number"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: KIT_COLOR },
                  "&:hover fieldset": { borderColor: KIT_COLOR },
                  "&.Mui-focused fieldset": { borderColor: KIT_COLOR },
                },
                "& .MuiInputLabel-root": { color: KIT_COLOR },
                "& .MuiInputLabel-root.Mui-focused": { color: KIT_COLOR },
              }}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default Thresholdcom;
